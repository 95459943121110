namespace gotoAndPlay {
    export class Toggle {

        element: JQuery;
        input: JQuery;
        target: JQuery;
        inverse: JQuery;
        priority: JQuery;
        priorityInverse: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.input = this.element.find('input[type="checkbox"]');
            this.target = $('[data-toggle-' + this.element.data('target') + ']');
            if (this.element.data('target-inverse')) {
                this.inverse = $('[data-toggle-' + this.element.data('target-inverse') + ']');
            }
            if (this.element.data('target-priority')) {
                this.priority = $('.js-toggle[data-target="' + this.element.data('target-priority') + '"] input[type="checkbox"]');
                this.priorityInverse = $('.js-toggle[data-target-inverse="' + this.element.data('target-priority') + '"] input[type="checkbox"]');
            }

            this.bindEvents();

            // only init-display active toggles
            if (this.input.is(':visible')) {
                this.toggleVisible();
            }
        }

        bindEvents() {
            this.input.on('change', this.toggleVisible.bind(this));
        }

        toggleVisible() {
            // loop all data
            // if data-toggle != element.target > find(data-target).is(':checked')
            if (this.input.is(':checked')) {
                this.target.show();
                if (this.inverse) {
                    this.inverse.hide();
                }

                if (this.priority || this.priorityInverse) {
                    let priorityToggle = $('[data-toggle-' + this.element.data('target-priority') + ']');
                    if ((this.priority && this.priority.is(':checked')) || (this.priorityInverse && !this.priorityInverse.is(':checked'))) {
                        priorityToggle.show();
                    } else {
                        priorityToggle.hide();
                    }
                }
            } else {
                this.target.hide();
                if (this.inverse) {
                    this.inverse.show();
                }
            }
        }
    }

    function onInit(): void {
        jQuery('.js-toggle').each(function() {
            const toggle = new Toggle(this);
        });
    }

    jQuery(document).ready(onInit);
}
