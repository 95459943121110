namespace gotoAndPlay {

    export class Profits {
        element: JQuery;
        baseInput: JQuery;
        feeInput: JQuery;
        profitInput: JQuery;
        timescale: number;
        feePercent: number;

        constructor(target: HTMLElement) {
            this.element     = $(target);
            this.baseInput   = this.element.find('.js-profit-base input');
            this.feeInput    = this.element.find('.js-profit-fee input');
            this.profitInput = this.element.find('.js-profit-result input');
            this.timescale   = parseInt(this.element.data('time'));
            this.feePercent  = parseFloat(this.element.data('fee'));

            this.init();
        }

        init() {
            this.baseInput.on('keyup', this.updateProfits.bind(this));
        }

        updateProfits() {
            let fee    = 0;
            let profit = 0;
            let total  = 0;

            let base = parseFloat(this.baseInput.val());
            if (!isNaN(base)) {
                total = base;
                fee   = total * this.feePercent;
                profit = total - fee;
            }

            this.feeInput.val(fee.toFixed(2));
            this.profitInput.val(profit.toFixed(2));
        }
    }

    function onInit(): void {
        jQuery('.js-profit-calc').each(function() {
            const calc = new Profits(this);
        });
    }

    // Init on document ready
    jQuery(document).ready(onInit);
}
