namespace gotoAndPlay {

    $(function () {
        $('.js-map-area-slider').each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                const prevArrow: string = '<button type="button" class="map-area__nav map-area__nav--prev">' + Icon.render('chevron-left', 'map-area__nav-icon') + '</button>';
                const nextArrow: string = '<button type="button" class="map-area__nav map-area__nav--next">' + Icon.render('chevron-right', 'map-area__nav-icon') + '</button>';
                $(this).slick({
                    dots: false,
                    arrows: true,
                    prevArrow: prevArrow,
                    nextArrow: nextArrow,
                    infinite: true,
                    speed: 500,
                    adaptiveHeight: false,
                });
            }
        });
    });
}
