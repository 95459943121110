// import('import/js/lazysizes-umd.js');
// import('import/js/modernizr-custom.js');
// import('import/js/picturefill.min.js');
// import('import/js/objectFitPolyfill.min.js');
// import('import/js/jquery.maxlength.min.js');

namespace gotoAndPlay {

    interface IBreakpoints {
        xs_min: number;
        sm_min: number;
        md_min: number;
        lg_min: number;
    }

    interface IReCaptcha {
        include: boolean;
        siteKey: string;
        renderKey?: string;
    }

    export const LOADING_CLASS: string = 'is-loading';
    export const ACTIVE_CLASS: string = 'is-active';
    export const IS_INVALID: string = 'is-invalid';
    export const IS_VALID: string = 'is-valid';
    export const DISABLED_CLASS: string = 'is-disabled';
    export const BREAKPOINTS: IBreakpoints = {
        xs_min: 480,
        sm_min: 768,
        md_min: 1024,
        lg_min: 1170,
    };

    // WordPress variables
    export let svgPath: string;
    export let templatePath: string;
    export let idAuthPath: string;
    export let ajaxPath: string;
    export let apiBasePath: string;
    export let phpSessId: string;
    export let googleMapsApiKey: string;
    export let nonce: string;
    export let version: string;
    export let loggedIn: boolean;
    export let headerOffset: number = 90;
    export let lang: string;
    export let recaptcha: IReCaptcha;

    // Add jQuery just in case
    export let $: JQueryStatic = jQuery;

    /**
     * @brief      Helper class
     */
    export class Helper {

        /**
         * @brief      { Scrolls to top of the page }
         *
         * @param      event
         */
        static scrollToTop(event: JQueryEventObject = null, mobileOnly: boolean = false): void {
            if (mobileOnly && $(window).width() < gotoAndPlay.BREAKPOINTS.sm_min || !mobileOnly) {
                $('html, body').animate({
                    scrollTop: 0,
                }, 500);
            }
        }

        /**
         * @brief      { Scrolls to target from href }
         *
         * @param      event
         */
        static scrollTo(event: JQueryEventObject): void {

            let element = $(event.currentTarget);
            let selector = element.attr('href');
            let target = $(selector);

            if (target.length > 0) {
                event.preventDefault();
                if (element.data('change-href')) {
                    let top = $(window).scrollTop();
                    window.location.href = selector;
                    $(window).scrollTop(top);
                }
                Helper.scrollToTarget(target);
            }
        }

        /**
         * @brief      { Scrolls to target }
         *
         * @param      target
         */
        static scrollToTarget(target: JQuery, offsetTop: number = 0): void {

            $('html, body').animate({
                scrollTop: target.offset().top - offsetTop - (headerOffset ? headerOffset : 0),
            }, 500);
        }

        /**
         * @brief      { Scrolls to target }
         *
         * @param      target
         */
        static scrollContent(container: JQuery, elem: JQuery, animate: boolean = false): void {
            if (animate) {
                container.animate({
                    scrollTop: elem.offset().top - container.height() / 2 + elem.height() / 2,
                    scrollLeft: elem.offset().left - container.width() / 2 + elem.width() / 2,
                });
            } else {
                container.scrollTop(elem.offset().top - container.height() / 2 + elem.height() / 2);
                container.scrollLeft(elem.offset().left - container.width() / 2 + elem.width() / 2);
            }
        }

        /**
         * @brief      Determines if element is on screen.
         *
         * @return     True if is, False otherwise.
         */
        static isOnScreen(target: JQuery, partial: boolean = true): boolean {

            let win = $(window);
            let viewTop = win.scrollTop();
            let viewBottom = viewTop + win.height();
            let top = target.offset().top;
            let bottom = top + target.height();
            let compareTop = partial === true ? bottom : top;
            let compareBottom = partial === true ? top : bottom;
            return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
        }

        static isURL(str: string) {
            let regexp = /^https?:\/\//i;
            return regexp.test(str);
        }

        /**
         * @brief      Determines if ie.
         *
         * @return     True if ie, False otherwise.
         */
        static get isIE(): boolean | number {

            let ua: string = window.navigator.userAgent;
            let msie: number = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            let trident: number = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                let rv = ua.indexOf('rv:');
                return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }

            let edge: number = ua.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
            }

            // other browser
            return false;
        }

        /**
         * @brief      Determines if mobile device.
         *
         * @return     True if mobile device, False otherwise.
         */
        static get isMobileDevice(): boolean {

            return ((/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) ? true : false);
        }
    }

    interface IAjaxResponse {
        result: boolean;
        data: any;
        error: string;
        errors?: {
            [propName: string]: string;
        };
        urlParams?: string;
        reload?: boolean;
        redirect?: string;
    }

    export class Form {

        public static submitForm(form: JQuery): JQueryXHR {
            let ajaxParams = {
                type: 'POST',
                url: form.attr('action'),
                dataType: 'json',
            };
            let ajaxData: any = form.serialize();
            // specialized formData object for submitting files, when present
            if (form.find('input[type="file"]')) {
                ajaxData = new FormData((form[0] as HTMLFormElement));
                let files = false;
                form.find('input[type="file"]').each((i, el) => {
                    let input: JQuery = $(el);
                    if (input.data('files')) {
                        files = true;
                        let data = input.data('files');
                        for (let f = 0; f < data.length; f++) {
                            ajaxData.append('file[]', data[f], data[f].name);
                        }
                    }
                });

                ajaxParams['contentType'] = false;
                ajaxParams['processData'] = false;
            }
            ajaxParams['data'] = ajaxData;

            let xhr = $.ajax(ajaxParams);
            Form.onFormRequest(form);
            xhr.then((response: IAjaxResponse) => {
                if (response && response.result) {
                    if (response.result) {
                        if (response.urlParams) {
                            window.location.href = window.location.origin + window.location.pathname + response.urlParams;
                        } else if (response.reload) {
                            window.location.reload();
                        } else if (response.redirect) {
                            window.location.href = response.redirect;
                        }
                        return response;
                    }
                }
                Form.onFormResult(form, response);
                return response;
            }, (errorResponse) => {
                Form.onFormResult(form, errorResponse.responseJSON);
                return errorResponse.responseJSON;
            });
            return xhr;
        }

        public static get(url: string): JQueryXHR {
            return $.ajax({
                type: 'GET',
                url: url,
            });
        }

        public static clearForm(form) {
            form.removeClass(LOADING_CLASS);
            form.find('.' + IS_INVALID).removeClass(IS_INVALID);
            form.find('.' + IS_VALID).removeClass(IS_VALID);
            form.find('[data-errormsg="true"]').remove();
        }

        public static onFormRequest(form: JQuery) {
            Form.clearForm(form);
            form.addClass(LOADING_CLASS);
            let submitButton: JQuery = form.find('[type="submit"]');
            if (submitButton.length > 0) {
                submitButton.prop('disabled', true);
                submitButton.addClass(DISABLED_CLASS);
            }
        }

        public static addFormError(field: JQuery, error: string = null) {
            if (field.length) {
                let inner = field.closest('[class*="__inner"]');
                if (inner.length) {
                    inner.parent().addClass(IS_INVALID);
                    if (error) {
                        let elementType: string = null;
                        inner.attr('class').split(' ').forEach((value) => {
                            if (value.indexOf('__inner') !== -1) {
                                elementType = value.replace('__inner', '');
                            }
                        });
                        if (elementType) {
                            inner.after('<div class="' + elementType + '__errormsg" data-errormsg="true">' + error + '</div>');
                        }
                    }

                    if (inner.closest('.js-accordion').length) {
                        inner.closest('.js-accordion').addClass('is-open');
                    }
                } else {
                    field.parent().addClass(IS_INVALID);
                }
            }
        }

        public static onFormResult(form: JQuery, response: IAjaxResponse = null) {
            Form.clearForm(form);
            let submitButton: JQuery = form.find('[type="submit"]');
            if (submitButton.length > 0) {
                submitButton.prop('disabled', false);
                submitButton.removeClass(DISABLED_CLASS);
            }
            if (response) {
                if (response.errors) {
                    form.find('.js-accordion').removeClass('is-open');
                    for (let key in response.errors) {
                        if (key) {
                            let isString = typeof response.errors[key] === 'string';
                            Form.addFormError(form.find('[name="' + key + '"]'), (isString ? response.errors[key] : response.errors[key][0]));
                        }
                    }
                } else {
                    let elements = form.find(':input');
                    if (elements.length) {
                        elements.each(function() {
                            Form.addFormError($(this));
                        });
                        let errorField = form.find('.' + IS_INVALID + ' [class*="__inner"] :input');
                        if (errorField.length) {
                            Form.addFormError(errorField.last(), response.error);
                        }
                    }
                }
            }
        }
    }

    $(function() {
        // Magic happens here
        $(document).on('click', '.js-show-reviews', function(event) {
            $(this).hide();
            $('.js-review').show();
        });
        $(document).on('click', '.js-scroll-to-href', Helper.scrollTo);
        $(document).on('click', '.js-scroll-to-top', function(event) {
            Helper.scrollToTop(event, true);
        });
        $(document).on('submit', 'form[data-ajax="true"]', function(event) {
            event.preventDefault();
            let form = $(this);

            if (form.find('input[name="captcha-response-token"]').length) {
                // set captcha token, if form requires it
                grecaptcha.ready(function() {
                    grecaptcha.execute(gotoAndPlay.recaptcha.renderKey, {
                        action: 'register',
                    }).then(function(token) {
                        form.find('input[name="captcha-response-token"]').val(token);
                        Form.submitForm(form);
                    });
                });
            } else {
                Form.submitForm(form);
            }
        });
        $(document).on('change', '[data-name]', function() {
            $('[name="' + $(this).data('name') + '"]').val($(this).val()).trigger('change');
        });
        $(document).on('submit', '.js-review-form', function(event) {
            let textField = $(this).find('[name="review"]');
            if (!textField.val()) {
                event.preventDefault();
                textField.parents('.c-form-textfield').addClass('is-invalid');
                textField.focus();
            }
        });
        $(document).on('click', '.js-remove-image', function(event) {
            event.preventDefault();
            $(this).parents('.admin__detail-upload').find('.js-image-key').val('');
            $(this).parent().closest('figure').remove();
            $(this).remove();
        });
        $(document).on('click', '.js-delete-meta', function(event) {
            event.preventDefault();
            API.get('/' + $(this).data('type') + '/image/' + $(this).data('id')).then(() => {
                window.location.reload();
            }).fail(() => {
                window.location.reload();
            });
        });
        $(document).on('click', '.js-move-item', function(event) {
            event.preventDefault();

            let button = $(this);
            let item = button.closest('.js-item-container');
            let parent = item.parent();
            let items = parent.find('.js-item-container');
            let dir = button.data('dir');
            // move elements by swapping
            switch (dir) {
                case 'back':
                    if (item.index()) {

                        let swapTo = items.eq(item.index() - 1);
                        item.insertBefore(swapTo);

                        // set item to first
                        if (item.index() == 0) {
                            // set primary image size
                            item.removeClass('c-grid__col--sm-6').addClass('c-grid__col--sm-12');
                            swapTo.removeClass('c-grid__col--sm-12').addClass('c-grid__col--sm-6');
                        }
                    }
                    break;
                case 'forward':
                    if (item.index() != items.length - 1) {
                        let swapTo = items.eq(item.index() + 1);
                        item.insertAfter(swapTo);

                        // set item to first
                        if (swapTo.index() == 0) {
                            // set primary image size
                            swapTo.removeClass('c-grid__col--sm-6').addClass('c-grid__col--sm-12');
                            item.removeClass('c-grid__col--sm-12').addClass('c-grid__col--sm-6');
                        }
                    }
                    break;
            }
            // refresh collection
            items = parent.find('.js-item-container');

            // set move buttons display
            items.find('.js-move-item').removeClass('h-hidden');
            items.eq(0).find('.js-move-item[data-dir="back"]').addClass('h-hidden');
            items.eq(items.length - 1).find('.js-move-item[data-dir="forward"]').addClass('h-hidden');

        });
        $('img').on('load', function(event) {
            (window as any).objectFitPolyfill();
        });
        $(document).ready(function() {
            if ($('.js-scroll-content').length) {
                $('.js-scroll-content').each(function() {
                    let el = $(this);
                    Helper.scrollContent(el, el.find('.' + el.data('scroll-to')), false);
                });
            }
            $('.js-max-length').maxlength();
        });
    });

}
