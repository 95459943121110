namespace gotoAndPlay {

    export type TCookieConsent = 'agreement' | 'required' | 'functional' | 'analytics' | 'performance' | 'marketing'; // alt names: 'performance' | 'functional' | 'targeting'

    export class CookieNotice {

        element: JQuery;
        saveAllButton: JQuery;
        form: JQuery;
        description: JQuery;
        options: JQuery;
        currentSettings: string[];

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.saveAllButton = this.element.find('.js-cookie-accept-all');
            this.form = $('.js-cookie-modal-form');
            this.description = this.form.find('.cookie-notice__toggle');
            this.options = this.form.find('input[type="checkbox"]');
            this.currentSettings = [];

            this.init();
        }

        static set(cookieName: string, cookieValue: string, expireHours: number = 24 * 30): void {
            const d: Date = new Date();
            d.setTime(d.getTime() + ((expireHours ? expireHours : 1) * 60 * 60 * 1000));
            const expires: string = 'expires=' + d.toUTCString();
            document.cookie = cookieName + '=' + cookieValue + '; ' + expires + '; SameSite=Strict';
        }

        static get(cookieName: string): string {
            const name: string = cookieName + '=';
            const ca: string[] = document.cookie.split(';');
            for (let c of ca) {
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }

            return '';
        }

        static has(cookieName: string): boolean {
            const name: string = cookieName + '=';
            const ca: string[] = document.cookie.split(';');
            for (let c of ca) {
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return true;
                }
            }

            return false;
        }

        static hasAcceptedPolicy(type: TCookieConsent = 'agreement'): boolean {
            return CookieNotice.get('cookie-' + type) === 'yes';
        }

        static hasChangedPolicy(type: TCookieConsent = 'agreement'): boolean {
            return CookieNotice.has('cookie-' + type);
        }

        init() {
            if (!CookieNotice.hasAcceptedPolicy()) {
                this.showMessage();
            } else {
                this.loadCookieSettings();
                this.updateGtagConsent();
            }

            this.saveAllButton.on('click', this.acceptAllCookieSettings.bind(this));
            this.description.on('click', '.cookie-notice__toggle-title', this.toggleDescription.bind(this));
            this.form.on('submit', this.handleSubmit.bind(this));
        }

        loadCookieSettings() {
            // set currently selected cookies to check against saved options to see, if reloading is necessary
            this.options.each((index: number, element: HTMLInputElement) => {
                if (CookieNotice.hasAcceptedPolicy(element.name as TCookieConsent)) {
                    this.currentSettings.push(element.name);
                }
            });
        }

        updateGtagConsent() {
            if (typeof window.dataLayer !== 'undefined' && typeof window.gtag === 'function') {
                window.gtag('consent', 'update', {
                    ad_storage: CookieNotice.hasAcceptedPolicy('marketing') ? 'granted' : 'denied',
                    ad_user_data: CookieNotice.hasAcceptedPolicy('marketing') ? 'granted' : 'denied',
                    ad_personalization: CookieNotice.hasAcceptedPolicy('marketing') ? 'granted' : 'denied',
                    analytics_storage: CookieNotice.hasAcceptedPolicy('analytics') ? 'granted' : 'denied',
                    functionality_storage: CookieNotice.hasAcceptedPolicy('functional'),
                    personalization_storage: CookieNotice.hasAcceptedPolicy('functional'),
                });
            }
        }

        /**
         * Accept required cookies only
         *
         * @param event
         */
        acceptCookieSettings(event: JQueryEventObject) {
            event.preventDefault();

            CookieNotice.set('cookie-required', 'yes', 365 * 24);
            CookieNotice.set('cookie-agreement', 'yes', 365 * 24);

            // this.hideMessage();
            location.reload();
        }

        /**
         * Accept all cookies - defined by added option fields
         *
         * @param event
         */
        acceptAllCookieSettings(event: JQueryEventObject) {
            event.preventDefault();

            this.options.each((index: number, element: HTMLInputElement) => {
                CookieNotice.set('cookie-' + element.name, 'yes', 365 * 24);
            });

            CookieNotice.set('cookie-agreement', 'yes', 365 * 24);

            // this.hideMessage();
            location.reload();
        }

        toggleDescription(event: JQueryEventObject) {
            event.preventDefault();

            const target: JQuery = $(event.currentTarget);
            const toggle: JQuery = target.closest('.cookie-notice__toggle');

            if (toggle.hasClass('is-open')) {
                toggle.removeClass('is-open');
            } else {
                target.closest('form').find('.cookie-notice__toggle').removeClass('is-open');
                toggle.addClass('is-open');
            }
        }

        handleSubmit(event: JQueryEventObject) {
            event.preventDefault();
            const newSettings: string[] = [];

            $('.js-cookie-modal-form').find('input[type="checkbox"]').each((index: number, element: HTMLInputElement) => {
                CookieNotice.set('cookie-' + element.name, element.checked ? 'yes' : '', 365 * 24);

                if (element.checked) {
                    newSettings.push(element.name);
                }
            });

            CookieNotice.set('cookie-agreement', 'yes', 365 * 24);

            const diff: string[] = newSettings.filter((item) => !this.currentSettings.includes(item))
                .concat(this.currentSettings.filter((item) => !newSettings.includes(item)));

            if (diff.length) {
                location.reload();
            } else {
                $('.js-modal-medium').modaal('close');
            }
        }

        showMessage(): void {
            this.element.addClass('is-visible');
        }

        hideMessage(): void {
            this.element.removeClass('is-visible');
        }
    }

    function onInit(): void {
        $('.cookie-notice').each(function() {
            let cookieNotice = new CookieNotice(this);
        });
    }

    jQuery(document).ready(onInit);
}
