namespace gotoAndPlay {
    export class Refresher {

        element: JQuery;
        startTime: number;
        curTime: number;
        timer: number;

        constructor(target: HTMLElement) {
            this.element = jQuery(target);
            this.startTime = this.element.data('time');
            this.curTime = 0;

            this.setCountdown();
        }

        setCountdown(): void {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                window.location.reload();
            }, this.startTime * 1000);
        }
    }

    function onInit(): void {
        $('.js-auto-refresher').each(function () {
            let refresher = new Refresher(this);
        });
    }

    jQuery(document).ready(onInit);
}
