namespace gotoAndPlay {

    export class FixImageIE {
        element: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);

            this.fixImage(this.element);
        }

        fixImage(el) {
            let imgSrcset = el.find('.booking-slip__figure-img').attr('data-srcset');
            let array = imgSrcset.split(', ');
            let srcset = new Array();
            let w = window.innerWidth;
            let src;

            for (let i = 0; i < array.length; i++) {
                let pair = array[i].split(' ');
                srcset[pair[1].replace('w', '')] = pair[0];
            }

            for (let i = 0; i < srcset.length; i++) {
                if (w > parseInt(Object.keys(srcset)[i])) {
                    src = srcset[Object.keys(srcset)[i]];
                }
            }

            if (imgSrcset) {
                el.find('.booking-slip__figure').css('backgroundImage', 'url(' + src + ')').addClass('booking-slip__figure-img--isIE');
            }
        }
    }

    function onInit(): void {
        if (Helper.isIE) {
            jQuery('.booking-slip').each(function () {
                const fixImageIE = new FixImageIE(this);
            });
        }
    }

    jQuery(document).ready(onInit);
}
