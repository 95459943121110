// import '/import/js/Sortable.js';

namespace gotoAndPlay {

    class Menu {

        static showOptions(event: JQueryEventObject): void {
            event.preventDefault();
            event.stopImmediatePropagation();
            Menu.show($(this));
        }

        static show(target: JQuery) {
            Menu.hide();
            target.parent().addClass('is-open');
            $('body').addClass('menu-visible');
            setTimeout(() => {
                $(document).on('click', Menu.hide);
            }, 100);
        }

        static hide() {
            if ($('body').hasClass('menu-visible')) {
                $(document).find('.reorder__button').parent().removeClass('is-open');
                $('body').removeClass('menu-visible');
            }
            $(document).off('click', Menu.hide);
        }
    }

    $(function () {
        $(document).on('click', '.reorder__button', Menu.showOptions);
        let el = document.getElementById('reorder_items');
        if (el) {
            let sortable = Sortable.create(el, {
                draggable: '.reorder__item',
                animation: 250,
                filter: '.reorder__menu',
                ghostClass: 'reorder__ghost',
                chosenClass: 'reorder__chosen',
                dragClass: 'reorder__drag',
            });
        }
    });
}
