// import('import/js/slick.min.js');

namespace gotoAndPlay {

    $(function () {
        $('.js-product-hero-slick').each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                $(this).slick({
                    dots: false,
                    arrows: true,
                    prevArrow: '<button type="button" class="product-hero__nav product-hero__nav--prev">&lt;</button>',
                    nextArrow: '<button type="button" class="product-hero__nav product-hero__nav--next">&gt;</button>',
                    infinite: true,
                    speed: 300,
                    adaptiveHeight: false,
                    mobileFirst: true,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            settings: {
                                slidesToShow: 1,
                            },
                        },
                    ],
                });
            }
        });

        $(document).on('click', '.js-carousel-navigation-item', function (event) {
            event.preventDefault();
            let slide = $($(this).attr('href'));
            if (slide.length) {
                $(this).parents('.js-carousel-navigation').find('.is-active').removeClass('is-active');
                $(this).addClass('is-active');
                slide.parents('.slick-slider').slick('slickGoTo', slide.data('slick-index'));
            }
        });
        if ($('.gallery').length > 0) {
            $('.gallery').modaal({
                type: 'image',
                height: 100,
                after_open: function() {
                    $('#modaal-close').empty().html(Icon.render('close', 'product__gallery-close'));
                    $('.modaal-image img').css({'max-height': $(window).height() - 70});
                },
                before_image_change: function(current, next) {
                    next.find('img').css({'max-height': $(window).height() - 70});
                },
            });
            $('.js-open-gallery').on('click', function() {
                $('.gallery').first().trigger('click');
            });
        }
    });
}
