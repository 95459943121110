// import './import/clusterer.min.js';

namespace gotoAndPlay {

    import LatLng = google.maps.LatLng;

    export interface ICoords {
        lat?: number;
        lng?: number;
        label?: number;
    }

    /**
     * @brief      Map class
     */
    export class Map {

        element: JQuery;
        list: JQuery;
        item: JQuery;
        showMarker: boolean;
        addressChanged: boolean;
        editing: boolean;
        baseLat: number;
        baseLng: number;
        latLng: google.maps.LatLng;
        circle: google.maps.Circle;
        gmap: google.maps.Map;
        geocoder: google.maps.Geocoder;
        marker: google.maps.Marker;
        isResultsPage: boolean;
        isFrontPage: boolean;
        isLocalMap: boolean;
        userCurrentLocation: boolean;
        styles: any = [
            {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#444444',
                    },
                ],
            },
            {
                featureType: 'administrative.country',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        color: '#f2f2f2',
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: 45,
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                    {
                        color: '#4694ec',
                    },
                    {
                        visibility: 'on',
                    },
                ],
            },
        ];

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.showMarker = this.element.data('marker');
            this.baseLat = parseFloat(this.element.data('lat'));
            this.baseLng = parseFloat(this.element.data('lng'));
            this.editing = this.element.data('edit');
            this.latLng = this.baseLat && this.baseLng ? new google.maps.LatLng(this.baseLat, this.baseLng) : null;
            this.addressChanged = false;
            this.isResultsPage = this.element.parents('.results').length > 0;
            this.isFrontPage = this.element.parents('.search--home').length > 0;
            this.isLocalMap = this.element.hasClass('js-local-trailers');
            this.userCurrentLocation = this.element.data('location');

            this.init();
        }

        init() {
            this.addMap();
            this.geocoder = new google.maps.Geocoder();

            if ((this.showMarker || this.editing) && !this.isLocalMap) {
                this.updateMarker(this.latLng);
            } else if (!this.isLocalMap) {
                this.updateCircle(this.latLng);
            }

            if (this.isResultsPage && (this.showMarker || this.editing)) {
                this.updateMarker(this.latLng);
                this.updateCircle(this.latLng);
            }

            if (this.isLocalMap) {
                this.addCirclesAndLabels();
            }

            if (this.editing) {
                let searchInput = $('.js-google-map-search input');

                if (searchInput.length) {
                    let autocomplete = new google.maps.places.Autocomplete(searchInput.get(0) as HTMLInputElement, {
                        types: ['geocode'],
                        bounds: new google.maps.LatLngBounds(
                            { lat: 57.445574, lng: 21.591293 },
                            { lat: 59.602573, lng: 27.925285 },
                        ),
                    });

                    // triggered on autocomplete selection
                    // updates address fields and map marker position
                    autocomplete.addListener('place_changed', () => {
                        this.addressChanged = false;
                        let place = autocomplete.getPlace();
                        if (place.geometry) {
                            $('.js-map-tip').removeClass('h-hidden');
                            this.updateAddressFields(place, this.isFrontPage);
                            this.updateMarker(place.geometry.location, true);
                        }
                    });

                    // disable enter from submitting the whole form
                    google.maps.event.addDomListener(searchInput.get(0) as HTMLInputElement, 'keydown', (event) => {
                        if (event.keyCode != 13) {
                            this.addressChanged = true;
                        } else if (this.addressChanged) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    });

                    // triggered on map selection
                    // updated address fields and autocomplete
                    this.gmap.addListener('click', (event) => {
                        if (!this.isLocalMap) {
                            this.updateMarker(event.latLng);
                            this.geocoder.geocode({ location: event.latLng }, (results, status) => {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    if (results[0]) {
                                        this.updateAddressFields(results[0], true);
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }

        // update map marker position
        updateMarker(latLng: google.maps.LatLng, updateMap: boolean = false, markerOptions: any = null) {
            if (latLng && !this.marker) {
                this.marker = new google.maps.Marker(markerOptions ? markerOptions : {
                    map: this.gmap,
                    position: this.latLng,
                    icon: gotoAndPlay.templatePath + '/inc/img/map-marker.svg',
                });

            }

            if (this.marker) {
                this.marker.setPosition(latLng);
                if (updateMap) {
                    this.gmap.setCenter(latLng);
                }
            }
        }

        // update map circle position
        updateCircle(latLng: google.maps.LatLng, updateMap: boolean = false) {
            if (latLng && !this.circle) {
                this.circle = new google.maps.Circle({
                    map: this.gmap,
                    center: this.latLng,
                    radius: (this.isResultsPage ? 10000 : 500),
                    strokeColor: 'transparent',
                    fillColor: '#f05a22',
                    fillOpacity: .5,
                });
            }

            if (this.circle) {
                this.circle.setCenter(latLng);
                if (updateMap) {
                    this.gmap.setCenter(latLng);
                }
            }
        }

        addCirclesAndLabels(): void {
            const trailers: JQuery = this.element.parents('.map-area').find('.c-card:not(.slick-cloned)');
            const slider: JQuery = $('.map-area').find('.js-map-area-slider');
            let coords = [];

            trailers.each((index: number, element: Element): void => {
                const lat: number = parseFloat($(element).data('lat'));
                const lng: number = parseFloat($(element).data('lng'));

                if (lat && lng) {
                    coords.push({
                        lat: lat,
                        lng: lng,
                    });
                }
            });

            const infoWindow = new google.maps.InfoWindow({
                content: 'Haagis asub 2km raadiuses', // ToDo: use data for translatable text
                disableAutoPan: true,
            });

            const circles: google.maps.Circle[] = [];

            const markers = coords.map((position, i) => {
                const marker = new google.maps.Marker({
                    position,
                    label: ' ',
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 8.5,
                        fillColor: 'transparent',
                        fillOpacity: 0.5,
                        strokeColor: 'transparent',
                    },
                });

                circles.push(new google.maps.Circle({
                    radius: 1000,
                    fillColor: '#f05a22',
                    strokeColor: 'transparent',
                    strokeOpacity: 0.75,
                }));

                circles[i].bindTo('center', marker, 'position');
                circles[i].bindTo('map', marker, 'map');

                circles[i].addListener('click', () => {
                    infoWindow.open(this.gmap, marker);

                    circles.forEach((circle: google.maps.Circle): void => {
                        circle.setOptions({
                            strokeColor: 'transparent',
                            strokeOpacity: 0.75,
                        });
                    });

                    circles[i].setOptions({
                        strokeColor: '#f05a22',
                        strokeOpacity: 1,
                    });

                    if (slider.length > 0 && slider.hasClass('slick-initialized')) {
                        slider.slick('slickGoTo', i);
                    }
                });

                return marker;
            });

            const renderer = {
                render: ({ count, position }) => {

                    const marker: google.maps.Marker = new google.maps.Marker({
                        label: {
                            text: String(count),
                            color: 'white',
                            fontSize: '16px',
                        },
                        icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: 25,
                            fillColor: '#f05a22',
                            fillOpacity: 0.5,
                            strokeColor: 'transparent',
                        },
                        position,
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });

                    return marker;
                },
            };

            const clusters = new markerClusterer.MarkerClusterer({
                map: this.gmap,
                markers: markers,
                renderer: renderer,
                zoomOnClick: false,
                onClusterClick: (event, cluster, map) => {
                    this.gmap.setCenter(cluster.position);
                    this.gmap.setZoom(12);
                },
            });
        }

        updateAddressFields(place, setDisplay: boolean = false) {
            let addressData: any = {};

            for (let i = 0; i < place.address_components.length; i++) {
                let component = place.address_components[i];

                // filter out neccesary address components
                switch (component.types[0]) {
                    case 'country':
                        addressData.country = component.long_name;
                        break;
                    case 'locality':
                        addressData.city = component.long_name;
                        break;
                    case 'sublocality_level_1':
                        addressData.city_sub = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        addressData.county = component.long_name;
                        break;
                    case 'street_number':
                        addressData.streetNumber = component.long_name;
                        break;
                    case 'route':
                        addressData.route = component.long_name;
                        break;
                    case 'postal_code':
                        addressData.postalCode = component.long_name;
                        break;
                    case 'neighborhood':
                        addressData.neighborhood = component.long_name;
                        break;
                }
            }
            let address = addressData.neighborhood;

            if (addressData.route && addressData.streetNumber) {
                address = addressData.route + ' ' + addressData.streetNumber;
            } else if (addressData.route) {
                address = addressData.route;
            }

            // address, addressData.city, addressData.county, addressData.country
            // set form field values
            $('[name="latitude"]').val(place.geometry.location.lat());
            $('[name="longitude"]').val(place.geometry.location.lng());
            $('[name="country"]').val(addressData.country);
            $('[name="county"]').val(addressData.county);
            $('[name="city_sub"]').val(addressData.city_sub);
            $('[name="city"]').val(addressData.city);
            $('[name="address"]').val(address);
            $('[name="postal_code"]').val(addressData.postalCode);

            let components = [address, addressData.city, addressData.county, addressData.country];

            if (this.isResultsPage) {
                $('[name="full_address"]').val(components.filter((value) => value).join(', '));
                $('.js-search-form').find('[name="location"]').val($('[name="full_address"]').val()).trigger('change');
            }

            if (setDisplay) {
                // clears empty values, joins to a single address and sets as field
                $('[name="full_address"]').val(components.filter((value) => value).join(', '));
            }
        }

        addMap() {
            let latLng = this.latLng;
            let zoom: number;

            if (this.isResultsPage) {
                zoom = 9;
            } else if (this.isLocalMap) {
                zoom = 11;
            } else {
                zoom = 13;
            }

            if (!latLng) {
                latLng = new google.maps.LatLng(58.3750202, 26.6975768);
            }

            this.gmap = new google.maps.Map(this.element[0], {
                center: latLng,
                disableDefaultUI: true,
                zoomControl: true,
                draggable: true,
                styles: this.styles,
                scrollwheel: this.isLocalMap,
                zoom: zoom,
                maxZoom: 15,
            });

            if (this.userCurrentLocation && !this.isFrontPage) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((location) => {
                        if (location) {
                            const mapLocation: LatLng = new google.maps.LatLng(location.coords.latitude, location.coords.longitude);
                            this.gmap.setCenter(mapLocation);

                            if (this.isResultsPage) {
                                this.updateMarker(mapLocation);
                                this.updateCircle(mapLocation);
                                this.geocoder.geocode({ location: mapLocation }, (results, status) => {
                                    if (status === google.maps.GeocoderStatus.OK) {
                                        if (results[0]) {
                                            this.updateAddressFields(results[0], true);
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        }
    }

    /**
     * @brief      Triggers on document ready
     */
    function onInit(): void {
        if (((window as any).google === undefined || (window as any).google.maps === undefined) && $('.js-google-map').length > 0) {
            let scriptId = 'google-maps-loader';
            let script = document.getElementById(scriptId) as HTMLScriptElement;
            if (!script) {
                script = document.createElement('script');
                script.id = scriptId;
                script.type = 'text/javascript';
                script.async = true;
                script.defer = true;
                script.src = 'https://maps.googleapis.com/maps/api/js?key=' + gotoAndPlay.googleMapsApiKey + '&sensor=false&language=' + gotoAndPlay.lang + '&callback=googleMapsLoader' + ($('.js-google-map-search').length ? '&libraries=places' : '');
                (window as any).googleMapsLoader = function () {
                    onInit();
                };
                document.body.appendChild(script);
            }
        } else {
            $('.js-google-map').each(function () {
                let map = new Map(this);
            });
        }
    }

    // Init on document ready
    $(document).ready(onInit);
}
