// import '/import/js/moment.js';
// import '/import/js/datepicker.js';
// import '/import/js/i18n/datepicker.et.js';
// import '/import/js/i18n/datepicker.en.js';

namespace gotoAndPlay {

    export class AvailabilityPicker {

        private xhr: JQueryXHR;
        private element: JQuery;
        private pickupInput: JQuery;
        private returnInput: JQuery;
        private isLoaded: boolean;
        private listingId: number;
        private minDays: number;
        private maxDays: number;
        private pickupDate: moment.Moment;
        private returnDate: moment.Moment;
        private startDate: moment.Moment;
        private endDate: moment.Moment;
        private picker: any;
        private isInline: boolean;
        private timeout: any;
        private calculationData: any;
        private disabledDates: any;
        private updatePath: boolean;

        constructor(element: JQuery) {
            this.element    = element;
            this.picker     = this.element.data('dateRangePicker');
            this.listingId  = this.element.data('listing-id');
            this.isInline   = !!this.element.data('inline');
            this.updatePath = typeof this.element.data('update-path') !== 'undefined' && this.element.data('update-path');
            AvailabilityPicker.setMomentLocale();
            if (this.listingId) {
                this.pickupInput = element.find('[name="pickup_date"]');
                this.returnInput = element.find('[name="return_date"]');
                if (this.pickupInput.val()) {
                    this.pickupDate = moment(this.pickupInput.val(), 'YYYYMMDDHHmmss');
                }
                if (this.returnInput.val()) {
                    this.returnDate = moment(this.returnInput.val(), 'YYYYMMDDHHmmss');
                }
                this.element.on('datepicker-change', this.onChange.bind(this));
                this.element.on('datepicker-first-date-selected', this.onStartDateSelected.bind(this));
                this.element.on('datepicker-last-date-selected', this.setAvailableTimes.bind(this));
                this.element.on('datepicker-open', this.setAvialbleTimesOpen.bind(this));
                this.load().then((response) => {
                    this.startDate = moment(response.min_date);
                    this.endDate   = moment(response.max_date);
                    this.minDays   = response.min_length > 1 ? response.min_length + 1 : 1;
                    this.maxDays   = response.max_length >= 1 ? response.max_length + 1 : 1;
                    this.picker.setOptions({
                        startDate: moment(response.min_date),
                        endDate: moment(response.max_date),
                        minDays: this.minDays,
                        maxDays: this.maxDays,
                        beforeShowDay: this.onBeforeShowDay.bind(this),
                    });
                    this.disabledDates = response.disabled_dates;
                    this.calculate();
                    this.isLoaded = true;

                    this.element.trigger('availability.loaded');
                });
            }
        }

        onBeforeShowDay(date) {
            let valid: [boolean, string, string] = [true, '', ''];
            const dateString                     = moment(date).format('YYYY-MM-DD');

            if (!dateString) {
                return [false];
            }

            if (this.disabledDates && typeof this.disabledDates[dateString] !== 'undefined') {
                if (this.disabledDates[dateString] === true) {
                    valid[0] = false;
                } else if (this.disabledDates[dateString].length) {
                    valid[1] = 'isPartial';
                    if (!Helper.isMobileDevice) {
                        valid[2] = AvailabilityPicker.buildDateTooltip(date, this.disabledDates[dateString]);
                    }
                }
            }

            return valid;
        }

        onStartDateSelected(event, data) {
            let selectedDate = moment(data.date1);
            let startDate    = this.startDate;
            let endDate      = this.endDate;
            $.each(this.disabledDates, (date, hours) => {
                date = moment(date, 'Y-m-d');
            });
            this.picker.setOptions({
                startDate: startDate,
                endDate: endDate,
            });
        }

        /**
         * Set initial available datepicker times
         *
         * @param event
         * @param data
         */
        setAvialbleTimesOpen(event, data) {
            if (!Helper.isMobileDevice && !this.isInline) {
                return;
            }

            this.setAvailableTimes(event, {
                date1: this.pickupDate.toDate(),
                date2: this.returnDate.toDate(),
            });
        }

        /**
         * Disable mobile datepicker times
         *
         * @param event
         * @param data
         */
        setAvailableTimes(event, data) {
            if (!Helper.isMobileDevice && !this.isInline) {
                return;
            }

            if (this.isLoaded && data.date1 && data.date2 && this.disabledDates) {
                const dateStart = moment(data.date1).format('YYYY-MM-DD');
                const dateEnd   = moment(data.date2).format('YYYY-MM-DD');
                const timeStart = $('.time1 select');
                const timeEnd   = $('.time2 select');

                // reset time selection
                timeStart.find('option').prop('disabled', false);
                timeEnd.find('option').prop('disabled', false);

                if (typeof this.disabledDates[dateStart] !== 'undefined' && this.disabledDates[dateStart] !== true) {
                    for (let disabledStart of this.disabledDates[dateStart]) {
                        let opt = timeStart.find('option[value="' + disabledStart + '.00"]');
                        if (opt.length) {
                            opt.prop('disabled', true);
                        }
                    }

                    // set 1st available opt as selected, if it gets disabled by date selection
                    if (timeStart.find('option:selected').prop('disabled')) {
                        timeStart.val(timeStart.find('option:not([disabled])').first().attr('value'));
                        timeStart.trigger('change');
                    }
                }

                if (typeof this.disabledDates[dateEnd] !== 'undefined' && this.disabledDates[dateEnd] !== true) {
                    for (let disabledEnd of this.disabledDates[dateEnd]) {
                        let opt = timeEnd.find('option[value="' + disabledEnd + '.00"]');
                        if (opt.length) {
                            opt.prop('disabled', true);
                        }
                    }

                    // add additional hour to end time selection as return is possible on hour start
                    if (this.disabledDates[dateEnd][this.disabledDates[dateEnd].length - 1] == 23) {
                        timeEnd.find('option[value="' + this.disabledDates[dateEnd][0] + '.00"]').prop('disabled', false);
                    }

                    if (timeEnd.find('option:selected').prop('disabled')) {
                        timeEnd.val(timeEnd.find('option:not([disabled])').last().attr('value'));
                        timeEnd.trigger('change');
                    }
                }

                $('.time').trigger('loadselect.select');
            }
        }

        onChange(event, data) {
            if (this.isLoaded && data.date1 && data.date2) {
                this.pickupDate = moment(data.date1);
                this.returnDate = moment(data.date2);
                this.calculateWithDelay();
            }
        }

        load() {
            if (!this.xhr) {
                this.xhr = API.get('/listings/' + this.listingId + '/availability');
                this.xhr.then((response) => {
                    return response;
                });
            }

            return this.xhr;
        }

        calculateWithDelay(delay: number = 500) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.calculate();
            }, delay);
        }

        calculate() {
            if (this.pickupDate) {
                $('.js-pickup-date').text(this.pickupDate.format('DD.MM.YYYY HH:mm'));
            }
            if (this.returnDate) {
                $('.js-return-date').text(this.returnDate.format('DD.MM.YYYY HH:mm'));
            }

            if (this.pickupDate && this.returnDate && this.listingId) {
                let calculationData = {
                    listingId: this.listingId,
                    pickup_date: this.pickupDate.format('YYYY-MM-DD HH:mm'),
                    return_date: this.returnDate.format('YYYY-MM-DD HH:mm'),
                };
                if (JSON.stringify(calculationData) != JSON.stringify(this.calculationData)) {
                    this.calculationData = calculationData;
                    booking.calculate(calculationData);
                }
            }

            if (window.history && window.history.replaceState) {
                if (this.updatePath) {
                    const inputParam  = this.pickupInput.val();
                    const returnParam = this.returnInput.val();
                    const queryStr    = '?pickup_date=' + inputParam + '&return_date=' + returnParam;

                    if (window.location.search !== queryStr) {
                        // just replace date query params, this page shouldn't have anything else anyway
                        history.replaceState(null, null, queryStr);
                    }
                }
            }
        }

        /**
         * List allowed hours for partially available dates
         *
         * @param datetime
         * @param hours
         */
        public static buildDateTooltip(datetime, hours) {
            const date  = moment(datetime);
            let tooltip = '<span>' + date.format('DD.MM') + '<br>Vabad kellaajad</span><br><br>'; // fgdsf more JS strings
            let ttRows  = [];

            let ttRow = [];
            // loop all hours to keep ones not disabled
            for (let t = 0; t < 24; t++) {
                // collect continious hours to a sngle toolitp row
                if (!hours.includes(t)) {
                    if (!ttRow.length || ttRow[ttRow.length - 1] + 1 == t) {
                        ttRow.push(t);
                    } else if (ttRow.length) {
                        ttRows.push(ttRow);
                        ttRow = [t];
                    }
                }

                if (t == 23) {
                    ttRows.push(ttRow);
                }
            }

            for (let r = 0; r < ttRows.length; r++) {
                let row = ttRows[r];

                // append additional hour to selectable range, to indicate possible return time option
                if (row[row.length - 1] !== 23) {
                    row.push(row[row.length - 1] + 1);
                }

                // build tooltip range row
                tooltip += '<strong>' + ('0' + row[0]).substr(-2) + ':00';
                if (row.length > 1) {
                    tooltip += ' - ' + ('0' + row[row.length - 1]).substr(-2) + ':00';
                }
                tooltip += '</strong>';

                if (r + 1 != ttRows.length) {
                    tooltip += '<br>';
                }
            }

            return tooltip;
        }

        public static setMomentLocale() {
            let locale = {
                months: 'Jaanuar_Veebruar_Märts_Aprill_Mai_Juuni_Juuli_August_September_Oktoober_November_Detsember'.split('_'),
                monthsShort: 'Jaan_Veebr_Märts_Apr_Mai_Juuni_Juuli_Aug_Sept_Okt_Nov_Dets'.split('_'),
                weekdays: 'Pühapäev_Esmaspäev_Teisipäev_Kolmapäev_Neljapäev_Reede_Laupäev'.split('_'),
                weekdaysShort: 'P_E_T_K_N_R_L'.split('_'),
                weekdaysMin: 'P_E_T_K_N_R_L'.split('_'),
                longDateFormat: {
                    LT: 'H:mm',
                    LTS: 'H:mm:ss',
                    L: 'DD.MM.YYYY',
                    LL: 'D. MMMM YYYY',
                    LLL: 'D. MMMM YYYY H:mm',
                    LLLL: 'dddd, D. MMMM YYYY H:mm',
                },
                calendar: {
                    sameDay: '[Täna,] LT',
                    nextDay: '[Homme,] LT',
                    nextWeek: '[Järgmine] dddd LT',
                    lastDay: '[Eile,] LT',
                    lastWeek: '[Eelmine] dddd LT',
                    sameElse: 'L',
                },
                relativeTime: {
                    future: '%s pärast',
                    past: '%s tagasi',
                    s: AvailabilityPicker.processRelativeTime,
                    ss: AvailabilityPicker.processRelativeTime,
                    m: AvailabilityPicker.processRelativeTime,
                    mm: AvailabilityPicker.processRelativeTime,
                    h: AvailabilityPicker.processRelativeTime,
                    hh: AvailabilityPicker.processRelativeTime,
                    d: AvailabilityPicker.processRelativeTime,
                    dd: '%d päeva',
                    M: AvailabilityPicker.processRelativeTime,
                    MM: AvailabilityPicker.processRelativeTime,
                    y: AvailabilityPicker.processRelativeTime,
                    yy: AvailabilityPicker.processRelativeTime,
                },
                dayOfMonthOrdinalParse: /\d{1,2}\./,
                ordinal: (n) => {
                    return n;
                },
                week: {
                    dow: 1, // Monday is the first day of the week.
                    doy: 4,  // The week that contains Jan 4th is the first week of the year.
                },
            };

            moment.locale('et', locale);
        }

        public static processRelativeTime(n, withoutSuffix, key, isFuture) {
            let format = {
                s: ['mõne sekundi', 'mõni sekund', 'paar sekundit'],
                ss: [n + 'sekundi', n + 'sekundit'],
                m: ['ühe minuti', 'üks minut'],
                mm: [n + ' minuti', n + ' minutit'],
                h: ['ühe tunni', 'tund aega', 'üks tund'],
                hh: [n + ' tunni', n + ' tundi'],
                d: ['ühe päeva', 'üks päev'],
                M: ['kuu aja', 'kuu aega', 'üks kuu'],
                MM: [n + ' kuu', n + ' kuud'],
                y: ['ühe aasta', 'aasta', 'üks aasta'],
                yy: [n + ' aasta', n + ' aastat'],
            };
            if (withoutSuffix) {
                return format[key][2] ? format[key][2] : format[key][1];
            }
            return isFuture ? format[key][0] : format[key][1];
        }
    }

    $(function() {
        $('.js-datepicker').each(function() {
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            $(this).datepicker({
                language: gotoAndPlay.lang,
                minDate: tomorrow,
                multipleDates: false,
            });
        });
        $('.js-disabled-dates').each(function() {
            let selectedDates: Date[] = [];
            if ($(this).data('target')) {
                let dates = $($(this).data('target')).val().split(',');
                if (dates) {
                    for (let i = 0; i < dates.length; i++) {
                        let date = moment(dates[i]);
                        if (date.isSameOrAfter(moment())) {
                            selectedDates.push(date.toDate());
                        }
                    }
                }
            }
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            $(this).datepicker({
                language: gotoAndPlay.lang,
                minDate: tomorrow,
                multipleDates: true,
                onSelect: function(formattedDate, dates: Date[], instance) {
                    if (instance.$el.data('target')) {
                        let datestring = [];
                        for (let i = 0; i < dates.length; i++) {
                            datestring.push(moment(dates[i]).format('YYYY-MM-DD'));
                        }
                        $(instance.$el.data('target')).val(datestring.join(','));
                    }
                },
            });
            $(this).data('datepicker').selectDate(selectedDates);
        });
    });

}
