namespace gotoAndPlay {

    export class Loader {

        private element: JQuery;
        private modifier;

        constructor(element: JQuery, modifier: string = 'default') {
            this.element = element;
            this.modifier = modifier;
            this.add();
        }

        public add() {
            if (!this.element.find('.loader').length) {
                let target = this.element.find('.js-loader-target');
                let loader = '<div class="loader loader--' + this.modifier + '"><div class="loader__spinner"></div></div>';
                if (target.length) {
                    target.append(loader);
                } else {
                    this.element.append(loader);
                }
            }
        }

        public remove() {
            this.element.find('.loader').remove();
        }
    }
}
