namespace gotoAndPlay {

    $(function () {
        $(document).on('click', '.js-open-menu', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $('body').toggleClass('h-menu-open');
        });
        $('.header__list-item').on('click', function(event) {
            if (window.innerWidth >= 1024 && !$(event.target).closest('.messages').length) {
                $(this).toggleClass('is-active');
                $('body').toggleClass('messages-open');
            }
        });
        // close open menus
        $(document).on('click', function(event) {
            if (!$(event.target).closest('.header__list-item').length) {
                $('.header__list-item').removeClass('is-active');
                $('body').removeClass('messages-open');
            }
        });

        $(document).on('click', '.js-message-close', function(event) {
            $(this).closest('.js-message-content').slideUp();
        });

        if ($('body').hasClass('front')) {
            $(document).on('scroll', function(event) {
                let scrollPosition: number = 0;

                const element: JQuery = $('.header');
                const currentPosition: number = $(document).scrollTop();

                // Check whether scroll position is at top or not.
                // Can be less than zero when scrolling past top in Mac OS Safari.
                if (currentPosition <= 0) {
                    element.removeClass('has-scrolled is-scrolling-up');
                } else {
                    element.addClass('has-scrolled');
                }

                // Check scroll direction.
                // 7 is a magic number so scroll direction would not toggle when scroll jitters.
                if (currentPosition < (scrollPosition - 7)) {
                    element.addClass('is-scrolling-up');
                    element.removeClass('is-scrolling-down');
                } else if (currentPosition > (scrollPosition + 7)) {
                    element.addClass('is-scrolling-down');
                    element.removeClass('is-scrolling-up');
                }

                // Save scroll position for next comparison.
                scrollPosition = currentPosition;
            });
        }
    });
}
