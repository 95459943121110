// import('import/js/modaal.js');
// Read more: https://github.com/humaan/Modaal

namespace gotoAndPlay {

    /**
     * @brief      Modaal class
     */
    export class Modaal {

        resets: JQuery;

        constructor() {
            this.init();
            this.resets = null;
        }

        init() {

            let modal = this;

            $('.js-modal, .js-modal-review').each(function() {
                if (!$(this).data('modaal-scope')) {
                    $(this).modaal({
                        background: '#373a51',
                        overlay_opacity: .7,
                        hide_close: true,
                        overlay_close: !$(this).data('overlay-off'),
                        start_open: $(this).data('modaal-open'),
                        before_open: modal.beforeOpenModal,
                        after_open: modal.afterOpenModal,
                        after_close: modal.afterCloseModal,
                        before_close: modal.beforeCloseModal,
                    });

                    modal.openTrigger(this);
                }
            });

            $('.js-modal-medium').each(function() {
                if (!$(this).data('modaal-scope')) {
                    $(this).modaal({
                        background: '#373a51',
                        overlay_opacity: .7,
                        hide_close: true,
                        overlay_close: !$(this).data('overlay-off'),
                        start_open: $(this).data('modaal-open'),
                        custom_class: 'c-modaal--medium',
                        before_open: modal.beforeOpenModal,
                        after_open: modal.afterOpenModal,
                        after_close: modal.afterCloseModal,
                        before_close: modal.beforeCloseModal,
                    });

                    modal.openTrigger(this);
                }
            });

            $('.js-modal-small').each(function() {
                if (!$(this).data('modaal-scope')) {
                    $(this).modaal({
                        background: '#373a51',
                        overlay_opacity: .7,
                        hide_close: true,
                        overlay_close: !$(this).data('overlay-off'),
                        start_open: $(this).data('modaal-open'),
                        custom_class: 'c-modaal--small',
                        before_open: modal.beforeOpenModal,
                        after_open: modal.afterOpenModal,
                        after_close: modal.afterCloseModal,
                        before_close: modal.beforeCloseModal,
                    });

                    modal.openTrigger(this);
                }
            });

            $('.js-modal-small-alt').each(function() {
                if (!$(this).data('modaal-scope')) {
                    $(this).modaal({
                        background: '#373a51',
                        overlay_opacity: .7,
                        hide_close: true,
                        overlay_close: !$(this).data('overlay-off'),
                        start_open: $(this).data('modaal-open'),
                        custom_class: 'c-modaal--small c-modaal--bg-alt',
                        before_open: modal.beforeOpenModal,
                        after_open: modal.afterOpenModal,
                        after_close: modal.afterCloseModal,
                        before_close: modal.beforeCloseModal,
                    });

                    modal.openTrigger(this);
                }
            });

            $('.js-modal-search').each(function() {
                if (!$(this).data('modaal-scope')) {
                    $(this).modaal({
                        background: '#373a51',
                        overlay_opacity: .7,
                        hide_close: true,
                        overlay_close: !$(this).data('overlay-off'),
                        start_open: $(this).data('modaal-open'),
                        custom_class: 'c-modaal--small c-modaal--search',
                        before_open: modal.beforeOpenModal,
                        after_open: modal.afterOpenModal,
                        after_close: modal.afterCloseModal,
                        before_close: modal.beforeCloseModal,
                    });

                    modal.openTrigger(this);
                }
            });
        }

        beforeOpenModal(): void {
            if ($(window).width() <= gotoAndPlay.BREAKPOINTS.sm_min) {
                let offset = $('body').scrollTop();
                $('body').css('top', offset * -1 + 'px').addClass('modaal-open');
            }
        }

        afterOpenModal(): void {
            // set select values to ones before close, as modal cache clear them
            if (this.resets && this.resets.length) {
                this.resets.each((i, e) => {
                    let elem = $(e);
                    $('.modaal-wrapper [name="' + elem.attr('name') + '"]').val(elem.val());
                });

                this.resets = null;
            }

            $(document).trigger('reload');
        }

        afterCloseModal(): void {
            if ($(window).width() <= gotoAndPlay.BREAKPOINTS.sm_min) {
                $(document).trigger('modaal_closed', this);
                let offset = parseInt(document.body.style.top, 10);
                $('body').scrollTop(offset * -1).removeClass('modaal-open');
            }
        }

        beforeCloseModal(): void {
            this.resets = $('.modaal-wrapper select');
            $(document).trigger('before_modaal_closed', this);
        }

        openTrigger(el: HTMLElement) {
            if ($(el).data('open')) {
                $(el).click();
                $(el).data('open', false);
            }
        }
    }

    /**
     * @brief      Triggers on document ready
     */
    function onInit(): void {
        $(document).off('click', '.js-modal').on('click', '.js-modal', function(event) {
            event.preventDefault();
        });

        $(document).off('click', '.js-modal-small').on('click', '.js-modal-small', function(event) {
            event.preventDefault();
        });

        $(document).off('click', '.js-modal-medium').on('click', '.js-modal-medium', function(event) {
            event.preventDefault();
        });

        $(document).off('click', '.js-modal-review').on('click', '.js-modal-review', function(event) {
            event.preventDefault();
            $('.js-review-form [name="booking_id"]').val($(this).data('booking-id'));
        });

        $(document).off('click', '.js-modal-close').on('click', '.js-modal-close', function(event) {
            event.preventDefault();
            $('.js-modal').modaal('close');
            $('.js-modal-review').modaal('close');
            $('.js-modal-small').modaal('close');
            $('.js-modal-small-alt').modaal('close');
            $('.js-modal-medium').modaal('close');
            $('.js-modal-search').modaal('close');
        });

        let modaal: Modaal = new Modaal();
    }

    // Init on document ready
    $(document).ready(onInit);
    $(document).on('reload', (onInit));
}
