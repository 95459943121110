namespace gotoAndPlay {

    export interface IDatepickerSettings {
        col4Class?: string;
        col6Class?: string;
        col12Class?: string;
        datepickerColClass?: string;
        openClass?: string;
        customDateOpenClass?: string;
        periodColClass?: string;
        searchButtonColClass?: string;
        searchClass?: string;
    }

    export class Datepicker {

        static openHiddenFields(event?: JQueryEventObject) {
            if (event) {
                event.preventDefault();
            }

            const settings: IDatepickerSettings = {
                col4Class: 'c-grid__col--md-4',
                col6Class: 'c-grid__col--md-6',
                col12Class: 'c-grid__col--md-12',
                datepickerColClass: '.search__datepicker-col',
                openClass: 'is-open',
                customDateOpenClass: 'is-custom-date-open',
                periodColClass: '.c-datepicker__period-col',
                searchButtonColClass: '.search__button-col',
                searchClass: '.search',
            };

            const currentTarget: JQuery = event ? $(event.currentTarget) : $(document).find('.c-datepicker__period');
            const element: JQuery = currentTarget.parents('.c-datepicker');
            const isFrontSearch: boolean = element.parents(settings.datepickerColClass).length > 0;

            if (currentTarget.length === 0 || currentTarget.find('select').length === 0) {
                return;
            }

            currentTarget.find('select').formSelect();

            const value: string = currentTarget.find('select').formSelect('getSelectedValues')[0];

            if (value && value === 'custom' && element.hasClass('c-datepicker--period')) {
                element.addClass(settings.openClass);

                if (isFrontSearch) {
                    $(settings.searchButtonColClass).addClass(settings.col12Class).removeClass(settings.col6Class);
                    element.parents(settings.searchClass).addClass(settings.customDateOpenClass);
                    element.parents(settings.datepickerColClass).removeClass(settings.col6Class).addClass(settings.col12Class);
                    element.find(settings.periodColClass).removeClass(settings.col12Class).addClass(settings.col4Class);
                }

            } else {
                element.removeClass(settings.openClass);

                if (isFrontSearch) {
                    $(settings.searchButtonColClass).addClass(settings.col6Class).removeClass(settings.col12Class);
                    element.parents(settings.searchClass).removeClass(settings.customDateOpenClass);
                    element.parents(settings.datepickerColClass).removeClass(settings.col12Class).addClass(settings.col6Class);
                    element.find(settings.periodColClass).removeClass(settings.col4Class).addClass(settings.col12Class);
                }
            }
        }
    }

    $(function() {
        $(document).on('change', '.c-datepicker__period', Datepicker.openHiddenFields);
        $(document).ready(function() {
            Datepicker.openHiddenFields();
        });
    });
}
