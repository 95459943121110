namespace gotoAndPlay {

    class Options {

        static showOptions(event: JQueryEventObject): void {
            event.preventDefault();
            event.stopImmediatePropagation();
            Options.show($(this));
        }

        static show(target: JQuery) {
            Options.hide();
            target.parent().addClass('is-open');
            $('body').addClass('options-visible');
            setTimeout(() => {
                $(document).on('click', Options.hide);
            }, 100);
        }

        static hide() {
            if ($('body').hasClass('options-visible')) {
                $(document).find('.table__button').parent().removeClass('is-open');
                $('body').removeClass('options-visible');
            }
            $(document).off('click', Options.hide);
        }
    }

    $(function () {
        $(document).on('click', '.table__button', Options.showOptions);
        $(document).on('click', '.sortable', function (event) {
            event.preventDefault();
            if ($(this).hasClass('is-asc')) {
                $(this).removeClass('is-asc').addClass('is-desc');
            } else if ($(this).hasClass('is-desc')) {
                $(this).removeClass('is-desc').addClass('is-neutral');
            } else {
                $(this).removeClass('is-neutral').addClass('is-asc');
            }
        });
        $('.multicol').each(function() {
            let rowCount = $(this).find('.table tr').length;
            let middleRow = Math.ceil(rowCount / 2);
            $(this).find('.table tr:first').css('border', 'none');
            $(this).find('.table tr:eq(' + middleRow + ')').css('border', 'none');
        });
    });

}
