namespace gotoAndPlay {
    export class Notification {

        element: JQuery;
        closeBtn: JQuery;
        closeTimeout: number;

        constructor(target: HTMLElement) {
            this.element = jQuery(target);
            this.closeBtn = this.element.find('.js-notification-close');

            if (this.element.data('timed')) {
                this.closeTimeout = setTimeout(() => {
                    this.close();
                }, 3000);
            }
            this.bindEvents();
        }

        bindEvents(): void {
            this.closeBtn.on('click', this.close.bind(this));
        }

        close(): void {
            clearTimeout(this.closeTimeout);
            this.element.fadeOut();
        }
    }

    function onInit(): void {
        $('.notification').each(function () {
            let notification = new Notification(this);
        });
    }

    jQuery(document).ready(onInit);
}
