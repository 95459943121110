// import('import/js/jquery.easing.1.3.js');
// import('import/js/materialize.js');
// import('import/js/select.js');
namespace gotoAndPlay {

    $(document).ready(function() {

        let initSelect = function() {
            let materialSelect: any = $('select').not('.is-disabled').not('.is-native').map((i, e) => {
                // skip initing modal selects, will be created by reload event on modal open
                if (!$(e).closest('.c-modaal').length) {
                    return e;
                }
            });
            materialSelect.each((i, e) => {
                let elem = $(e);
                // reset built select inside modals
                if (elem.parents('.select-wrapper').length && elem.closest('.modaal-wrapper').length) {
                    elem.parent().find('span.caret').remove();
                    $('ul#' + elem.parent().find('input').data('target')).remove();
                    elem.parent().find('input').remove();
                    elem.unwrap();
                }
            });

            buildSelect(materialSelect);
        };

        let loadSelect = function (event: JQueryEventObject) {
            const target: JQuery = $(event.currentTarget);
            const select: JQuery = target.find('select');

            if (select.length) {
                buildSelect(select);
            }
        };

        let buildSelect = function(collection: JQuery) {
            collection.formSelect();

            collection.each((index: number, element: HTMLElement) => {
                const target: JQuery = $(element);

                if (target.attr('multiple')) {
                    target.find('option[disabled][selected]').remove();
                    target.prevAll('.dropdown-content').find('li.disabled.selected').remove();
                    target.prevAll('input.select-dropdown').attr('placeholder', target.data('default-label'));
                }

            });
        };

        let initMultiSelect = function() {
            let multiSelect = $('select[multiple]');
            multiSelect.each((i, e) => {
                let elem = $(e);
                elem.before('<div class="c-form-select__multi-value js-select-label">' + elem.data('default-label') + '</div>');
            });

            multiSelect.on('change', (event: JQueryEventObject) => {
                let select = $(event.currentTarget);
                let label = select.parent().find('.js-select-label');

                let selectCount = select.find('option').filter(':selected').not(':disabled');
                if (!selectCount.length) {
                    // Vali...
                    label.html(select.data('default-label'));
                } else if (selectCount.length > 1) {
                    label.html(selectCount.length + ' ' + select.data('count-label'));
                } else {
                    label.html(selectCount.first().html());
                }
            });
        };

        if (!Helper.isMobileDevice) {
            initSelect();
            $(document).on('reload', initSelect);
            $(document).on('loadselect.select', loadSelect);
        } else {
            initMultiSelect();
        }
    });
}
