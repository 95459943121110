namespace gotoAndPlay {

    export interface IFeedbackSettings {
        ReadyClass?: string;
        SetClass?: string;
        OpenClass?: string;
        FeedbackClass?: string;
        ContentClass?: string;
        ControlClass?: string;
        InnerClass?: string;
    }

    export const FeedbackSettings = {
        ReadyClass: 'is-ready',
        SetClass: 'is-set',
        OpenClass: 'is-opened',
        FeedbackClass: 'js-feedback',
        ContentClass: 'js-feedback-content',
        ControlClass: 'js-feedback-control',
        InnerClass: 'js-feedback-rating',
    };

    export class Feedback {

        settings: IFeedbackSettings;
        element: JQuery;
        controls: JQuery;
        ratings: JQuery;
        autoSet: boolean;

        constructor(target: HTMLElement, settings: IFeedbackSettings = {}) {
            this.settings = jQuery.extend(FeedbackSettings, settings) as IFeedbackSettings;
            this.element = jQuery(target);
            this.controls = this.element.find('.' + this.settings.ControlClass);
            this.ratings = this.element.find('.' + this.settings.InnerClass);
            this.autoSet = false;

            if (!this.element.hasClass(this.settings.ReadyClass)) {
                this.init();
            }
        }

        init() {
            this.bindEvents();
            this.element.addClass(this.settings.ReadyClass);
        }

        bindEvents() {
            this.controls.on('click', this.forceOpen.bind(this));
            this.ratings.on('click', this.toggleNext.bind(this));
        }

        getFeedback(): JQuery {
            let feedback: JQuery;
            if (this.element.hasClass(this.settings.FeedbackClass)) {
                feedback = this.element.addClass(this.settings.FeedbackClass);
            } else {
                feedback = this.element.parents('.' + this.settings.FeedbackClass);
            }
            return feedback.length ? jQuery(feedback.get(0)) : feedback;
        }

        forceOpen(event: JQueryEventObject): void {
            if (!this.autoSet) {
                let content = $(event.target).closest('.' + this.settings.ContentClass);
                if (!content.hasClass(this.settings.OpenClass)) {
                    content.addClass(this.settings.OpenClass);
                }
            } else {
                this.autoSet = false;
            }
        }

        toggleNext(event: JQueryEventObject): void {
            let rating = $(event.target).closest('.' + this.settings.InnerClass);
            let content = rating.parents('.' + this.settings.ContentClass);

            rating.addClass(this.settings.SetClass);

            let currentRatings = content.find('.' + this.settings.InnerClass);
            let currentSetRatings = content.find('.' + this.settings.SetClass);
            if (currentRatings.length == currentSetRatings.length && !content.hasClass(this.settings.OpenClass)) {
                content.addClass(this.settings.OpenClass);
                let nextContent = content.next('.' + this.settings.ContentClass);
                if (nextContent.length) {
                    this.autoSet = true;
                    nextContent.find('.' + this.settings.ControlClass).click();
                }

            }
        }
    }

    function onInit(): void {
        $('.js-feedback').each(function () {
            let feedback = new Feedback(this);
        });
    }

    jQuery(document).ready(onInit);
    $(document).on('reload', (onInit));
}
