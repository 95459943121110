// import '/import/js/veriff-incontext.min.js';

namespace gotoAndPlay {
    const Veriff = window.veriffSDK;

    export interface IVeriffFrame {
        close: () => void;
    }

    export class AuthValidate {
        element: JQuery;
        openBtn: JQuery;
        closeBtn: JQuery;
        preForm: JQuery;
        preFormError: JQuery;
        headerProgress: JQuery;
        options: JQuery;
        veriffBtn: JQuery;
        veriffRestart: JQuery;
        veriffControl: JQuery;
        veriffFrame: IVeriffFrame;
        account: JQuery;
        result: JQuery;
        steps: JQuery;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.openBtn = $('.js-validate-open');
            this.closeBtn = this.element.find('.js-validate-close');
            this.preForm = this.element.find('.js-validate-preform');
            this.preFormError = this.preForm.find('.js-validate-error');
            this.headerProgress = this.preForm.find('.validate__header--inprogress');
            this.options = this.element.find('.js-validate-options');
            this.veriffBtn = this.element.find('.js-validate-veriff');
            this.veriffRestart = this.element.find('.js-veriff-restart');
            this.veriffControl = this.element.find('.js-veriff-container');
            this.account = this.element.find('.js-validate-account');
            this.result = this.element.find('.js-validate-result');
            this.steps = this.element.find('.validate__steps');

            this.bindEvents();
        }

        bindEvents() {
            this.openBtn.on('click', this.open.bind(this));
            this.closeBtn.on('click', this.close.bind(this));
            this.veriffBtn.on('click', this.requestVeriffSession.bind(this));
            this.veriffRestart.on('click', this.requestVeriffSession.bind(this));
            this.account.on('submit', this.submitAccount.bind(this));
        }

        open(event: JQueryEventObject) {
            event.preventDefault();
            let el = $(event.currentTarget);
            this.reset();
            $('body').addClass('h-drawer-open');

            // trigger related sub-buttons
            switch (el.data('type')) {
                case 'veriff':
                    this.veriffBtn.click();
                    break;
            }
        }

        close(event: JQueryEventObject) {
            event.preventDefault();
            $('body').removeClass('h-drawer-open');
        }

        nextStep() {
            let current = this.steps.find('.is-current');
            let next = current.next('.validate__steps-item');

            if (next.length) {
                current.removeClass('.is-current');
                next.addClass('.is-current');
            }
        }

        showLoader() {
            // loader elem
        }

        renderVeriffMessage(message: string, global: boolean = true) {
            this.veriffControl.find('.js-veriff-message').html('<p>' + message + '</p>');

            // include message to validation controls
            if (global) {
                $('.js-validate-veriff-progress').text(message);
                $('.js-validate-veriff-progress').closest('.validate__message').removeClass('h-hidden');
            }
        }

        requestVeriffSession(event: JQueryEventObject) {
            this.showLoader();
            this.options.hide();
            this.preFormError.hide();
            this.veriffControl.show();
            this.veriffRestart.hide();

            // message - checking for session data
            // request session
            // new session > message - redirecting to authentication
            // existing session > message - validation in progress, please wait
            // failed session > message - auth failed, request new?

            // ToDo: poll session state before requesting a new one
            API.post('/validate/veriff/session').then((response) => {
                if (response.message) {
                    // message should be shown in every case to keep user up to date
                    this.renderVeriffMessage(response.message);
                }

                if (response.success && response.veriff) {
                    switch (response.veriff.status) {
                        case 'created':
                        case 'started':
                        case 'resubmission_requested':
                            this.veriffFrame = Veriff.createVeriffFrame({
                                url: response.veriff.url,
                                lang: gotoAndPlay.lang,
                                onEvent: (msg) => {
                                    const state: string = this.veriffControl.data('modal-' + msg.toLowerCase());

                                    if (state) {
                                        this.renderVeriffMessage(state, false);
                                    }

                                    switch (msg) {
                                        case Veriff.MESSAGES.STARTED:
                                            // no action, waiting for user input
                                            // this.validateVeriffSession(response.veriff_id, true);
                                            break;
                                        case Veriff.MESSAGES.CANCELED:
                                            // premature cancellation, restart process
                                            $('.js-validate-veriff-progress').text(response.messages.started);
                                            $('.js-validate-veriff-progress').closest('.validate__message').removeClass('h-hidden');
                                            this.veriffRestart.show();
                                            break;
                                        case Veriff.MESSAGES.FINISHED:
                                            // form completed, start polling for updates
                                            $('.js-validate-veriff-progress').text(response.messages.submitted);
                                            $('.js-validate-veriff-progress').closest('.validate__message').removeClass('h-hidden');
                                            this.validateVeriffSession(response.veriff.veriff_id);
                                            break;
                                    }
                                },
                            });
                            break;
                        case 'submitted':
                            // validation previosuly passed, start polling ongoing request
                            this.validateVeriffSession(response.veriff.veriff_id);
                            break;
                        case 'approved':
                            // continue straight to form
                            this.veriffControl.hide();

                            // fill fields
                            this.account.find('[name="auth_type"]').val('veriff');
                            this.account.find('[name="first_name"]').val(response.veriff.result?.firstName);
                            this.account.find('[name="last_name"]').val(response.veriff.result?.lastName);
                            this.account.find('[name="id_code"]').val(response.veriff.result?.idNumber);

                            // clear loader
                            // display account form
                            this.preForm.hide();
                            this.nextStep();
                            this.account.show();
                            break;
                        default:
                        // message should cover most cases
                        // this.preForm.addClass('has-errors');
                    }
                }
            }).fail((error) => {
                this.preForm.addClass('has-errors');
                if (error.responseJSON) {
                    this.preFormError.text(error.responseJSON.error).show();
                } else {
                    this.preFormError.text(error.responseText).show();
                }
                this.preFormError.show();
            });
        }

        validateVeriffSession(session: string) {
            this.preForm.find('.validate__header').hide();
            this.headerProgress.show();

            API.post('/validate/veriff/status', { veriff_id: session }).then((response) => {
                console.log(response);

                if (response.success) {
                    switch (response.status) {
                        case 'approved':
                            this.veriffControl.hide();

                            // fill fields
                            this.account.find('[name="auth_type"]').val('veriff');
                            this.account.find('[name="first_name"]').val(response.person?.firstName);
                            this.account.find('[name="last_name"]').val(response.person?.lastName);
                            this.account.find('[name="id_code"]').val(response.person?.idNumber);

                            // clear loader
                            // display account form
                            this.preForm.hide();
                            this.nextStep();
                            this.account.show();
                            break;
                        case 'resubmission_requested':
                            this.renderVeriffMessage(response.message);
                            // append reason message and code
                            // restart process
                            this.veriffRestart.show();
                            break;
                        case 'started':
                        case 'submitted':
                            // ToDo: if current.status != response.status
                            this.renderVeriffMessage(response.message);
                            setTimeout(() => {
                                this.validateVeriffSession(session);
                            }, 2500);
                            break;
                        default:
                            this.renderVeriffMessage(response.message);
                    }
                }
            }).fail((error) => {
                this.preForm.addClass('has-errors');
                if (error.message) {
                    this.preFormError.text(error.message).show();
                } else if (error.responseJSON) {
                    this.preFormError.text(error.responseJSON.error).show();
                } else {
                    this.preFormError.text(error.responseText).show();
                }
            });
        }

        submitAccount(event: JQueryEventObject) {
            event.preventDefault();

            // manually submit form to handle the response
            Form.submitForm(this.account).then((response) => {
                this.preForm.hide();
                this.account.hide();
                if (response.result) {
                    this.result.show();
                    setTimeout(() => {
                        window.location.reload();
                        // $('body').removeClass('h-drawer-open');
                    }, 1000);
                } else {
                    this.account.show();
                }
            }).fail((response) => {
                if (response.status === 401) {
                    // window.location.reload();
                }
            });
        }

        reset() {
            this.preForm.show();
            this.options.show();
            this.headerProgress.hide();
            this.preFormError.hide();
            this.veriffControl.hide();
            this.veriffRestart.hide();
            this.account.hide();
            this.result.hide();
        }
    }

    function onInit(): void {
        jQuery('.validate').each(function() {
            const authValidate = new AuthValidate(this);
        });
    }

    jQuery(document).ready(onInit);
}
