namespace gotoAndPlay {

    export interface IFormProxySettings {
        ControlClass?: string;
    }

    export const FormProxySettings = {
        ControlClass: 'js-form-proxy',
    };

    export class FormProxy {

        settings: IFormProxySettings;
        element: JQuery;
        fieldName: string;

        constructor(target: HTMLElement) {
            this.settings  = FormProxySettings;
            this.element   = jQuery(target);
            this.fieldName = this.element.data('proxy');
        }

        submitProxy() {
            let proxyField: JQuery  = $('[name="' + this.fieldName + '_proxy"]');
            let targetField: JQuery = $('[name="' + this.fieldName + '"]');

            console.log(proxyField.val());
            if (targetField.length) {
                if (proxyField.length) {
                    targetField.val(proxyField.val());
                }

                targetField.closest('form').submit();
            }
        }
    }

    function onProxySubmit(event: JQueryEventObject): void {
        event.preventDefault();
        let proxy: FormProxy = new FormProxy(this);
        proxy.submitProxy();
    }

    function onInit(): void {
        jQuery(document).on('click', '.' + FormProxySettings.ControlClass, onProxySubmit);
    }

    jQuery(document).ready(onInit);
}
