namespace gotoAndPlay {

    jQuery(document).ready(($) => {

        $(document).on('click', '.js-auth-register', (event) => {
            event.preventDefault();
            $('.auth').removeClass('is-visible');
            $('.auth--signup').addClass('is-visible');
        });

        $(document).on('click', '.js-auth-form-extend', (event) => {
            event.preventDefault();
            let selector = $(event.currentTarget).attr('href');
            $(selector).addClass('is-visible');
            $(event.currentTarget).parent().hide();
        });

        $(document).on('click', '.js-auth-email', (event) => {
            event.preventDefault();
            $('.auth').removeClass('is-visible');
            $('.auth--email').addClass('is-visible');
        });

        $(document).on('click', '.js-auth-login', (event) => {
            event.preventDefault();
            $('.auth').removeClass('is-visible');
            $('.auth--login').addClass('is-visible');
        });

        // init active modal
        if ($('.auth').hasClass('is-active')) {
            $('.auth').removeClass('is-visible');
            $('.auth.is-active').addClass('is-visible');
        }

    });

}
