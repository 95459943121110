// import('import/js/svgxuse.min.js');

namespace gotoAndPlay {
    export class Icon {

        static render(modifier: string, cssClass: string = ''): string {
            let returns = '';

            if (modifier) {
                returns = '<svg class="icon ' + cssClass + '"><use xlink:href="' + gotoAndPlay.svgPath + '#' + modifier + '"></use></svg>';
            }

            return returns;
        }
    }
}
