namespace gotoAndPlay {

    export interface IFileUploadSettingsClass {
        dragOver?: string;
        hasAdvancedUpload?: string;
        isUploading?: string;
        isError?: string;
        isSuccess?: string;
        filesContainer?: string;
        errorContainer?: string;
        loaderContainer?: string;
        loader?: string;
        removeButton?: string;
        addButton?: string;
        large?: string;
        small?: string;
    }

    export interface IFileUploadSettingsText {
        errorMaxFileCount?: string;
        errorMaxFilesCount?: string;
        errorSameFileNameExists?: string;
        remove?: string;
        removeImage?: string;
        add?: string;
    }

    export interface IFileUploadSettings {
        class?: IFileUploadSettingsClass;
        text?: IFileUploadSettingsText;
        maxFileCount?: number;
        ajaxMethod?: string;
    }

    export class FileUpload {

        public settings: IFileUploadSettings;
        public element: JQuery;
        public input: JQuery;
        public errorMsg: JQuery;
        public loaderContainer: JQuery;
        public filesContainer: JQuery;
        public errorContainer: JQuery;
        public api: string;
        public advancedUpload: boolean;
        public droppedFiles: any;
        public isUploading: boolean;

        constructor(target: HTMLInputElement, settings: IFileUploadSettings = {}) {

            this.settings = jQuery.extend({
                class: {
                    dragOver: 'is-dragover',
                    hasAdvancedUpload: 'has-advanced-upload',
                    isUploading: 'is-uploading',
                    isError: 'is-error',
                    isSuccess: 'is-success',
                    filesContainer: 'form-upload__content--files',
                    loaderContainer: 'form-upload__content--uploading',
                    errorContainer: 'js-file-errors',
                    loader: 'form-upload__loader',
                    removeButton: 'form-upload__list-remove',
                    addButton: 'form-upload__list-item--add',
                    large: 'form-upload--large-box',
                    small: 'form-upload--small-box',
                },
                text: {
                    errorMaxFileCount: 'Cannot add more than 1 file',
                    errorMaxFilesCount: 'Cannot add more than {count} files',
                    errorSameFileNameExists: 'File name "{name}" already in list',
                    remove: 'remove',
                    removeImage: 'Remove image',
                    add: 'add',
                },
                maxFileCount: 10,
                ajaxMethod: 'POST',
            }, settings) as IFileUploadSettings;

            this.advancedUpload = this.isAdvancedUpload();
            this.droppedFiles   = [];
            this.isUploading    = false;

            this.element         = jQuery(target);
            this.filesContainer  = this.element.find('.' + this.settings.class.filesContainer);
            this.loaderContainer = this.element.find('.' + this.settings.class.loaderContainer);
            this.errorContainer  = this.element.find('.' + this.settings.class.errorContainer);
            this.api             = this.element.data('api');
            this.input           = this.element.find('input[type="file"]');

            if (this.advancedUpload) {
                this.element.addClass(this.settings.class.hasAdvancedUpload);

                this.element.on('drag dragstart dragend dragover dragenter dragleave drop', this.preventDefault.bind(this));
                this.element.on('dragover dragenter', this.onDragOverEnter.bind(this));
                this.element.on('dragleave dragend drop', this.onDragLeaveEndDrop.bind(this));
                this.element.on('drop', this.onDrop.bind(this));

                this.input.on('change', this.onInputChange.bind(this));
            }
        }

        onInputChange(event: JQueryEventObject): void {

            let files = (event as any).currentTarget.files;

            if (files.length) {
                jQuery.each(files, (index, file) => {
                    if (!this.ifValueExistsInObjectArray('name', file.name)) {
                        if (this.droppedFiles.length < this.settings.maxFileCount) {
                            this.droppedFiles.push(file);
                        } else {
                            this.addError(this.settings.maxFileCount > 1 ? this.settings.text.errorMaxFilesCount.replace(/{count}/ig, this.settings.maxFileCount.toString()) : this.settings.text.errorMaxFileCount);

                            setTimeout(() => {
                                this.removeError();
                            }, 3000);

                            return;
                        }
                    } else {
                        this.addError(this.settings.text.errorSameFileNameExists.replace(/{name}/ig, file.name));

                        setTimeout(() => {
                            this.removeError();
                        }, 3000);

                        return;
                    }
                });

                this.uploadFiles();
            }
        }

        preventDefault(event: JQueryEventObject): void {

            event.preventDefault();
            event.stopPropagation();
        }

        onDragOverEnter(): void {

            if (this.validateFileCount()) {
                this.element.addClass(this.settings.class.dragOver);
            } else {
                this.addError(this.settings.maxFileCount > 1 ? this.settings.text.errorMaxFilesCount.replace(/{count}/ig, this.settings.maxFileCount.toString()) : this.settings.text.errorMaxFileCount);
            }
        }

        onDragLeaveEndDrop(): void {

            this.element.removeClass(this.settings.class.dragOver);
            this.removeError();
        }

        onDrop(event: JQueryEventObject): void {

            let droppedFiles = (event as any).originalEvent.dataTransfer.files;

            if (this.validateFileCount() && droppedFiles.length <= this.settings.maxFileCount) {
                jQuery.each((event as any).originalEvent.dataTransfer.files, (index, file) => {
                    this.droppedFiles.push(file);
                });

                this.uploadFiles();
            } else {
                this.addError(this.settings.maxFileCount > 1 ? this.settings.text.errorMaxFilesCount.replace(/{count}/ig, this.settings.maxFileCount.toString()) : this.settings.text.errorMaxFileCount);

                setTimeout(() => {
                    this.removeError();
                }, 3000);
            }
        }

        onRemoveButtonClick(event: JQueryEventObject): void {
            event.preventDefault();

            const toRemove = jQuery(event.currentTarget).closest('.listing__container');
            this.removeFile(toRemove.data('index'));

            toRemove.remove();
        }

        removeFile(index): void {

            this.droppedFiles.splice(index, 1);
            this.element.data('files', this.droppedFiles);

            if (this.droppedFiles.length < 1) {
                this.element.closest('.c-grid').find('.' + this.settings.class.removeButton).unbind('click');
                this.element.removeClass(this.settings.class.isSuccess);
            }
        }

        uploadFiles(): void {

            this.input.data('files', this.droppedFiles);
            if (this.isUploading) {
                return;
            }

            this.loaderContainer.append(this.getLoaderHTML());
            this.element.addClass(this.settings.class.isUploading).removeClass(this.settings.class.isError);
            this.isUploading = true;

            // For demo purposes
            /*
            setTimeout(() => {
                this.element.addClass(this.settings.class.isSuccess).removeClass(this.settings.class.isUploading);
                this.isUploading = false;

                // Remove the loader after it's content is faded out
                this.loaderContainer.on('transitionend webkitTransitionEnd oTransitionEnd', () => {
                    this.loaderContainer.find('.' + this.settings.class.loader).remove();
                    this.loaderContainer.unbind('transitionend webkitTransitionEnd oTransitionEnd');
                });

                this.showFiles();
            }, 1000);
            */

            if (this.api) {
                API.postFiles(this.api, this.droppedFiles).done((response) => {
                    if (response.html) {
                        this.addFiles(response.html);
                    }
                    if (response.inputs) {
                        this.updateInputs(response.inputs);
                    }
                    if (response.errors) {
                        $.each(response.errors, (i, message) => {
                            if (Array.isArray(message)) {
                                this.addError(message[0]);
                            } else {
                                this.addError(message);
                            }
                        });
                    }
                }).fail((response) => {
                    this.addError(response.responseJSON.error);
                }).always((response) => {
                    this.element.addClass(this.settings.class.isSuccess).removeClass(this.settings.class.isUploading);
                    this.isUploading = false;

                    // Remove the loader after it's content is faded out
                    this.loaderContainer.on('transitionend webkitTransitionEnd oTransitionEnd', () => {
                        this.loaderContainer.find('.' + this.settings.class.loader).remove();
                        this.loaderContainer.unbind('transitionend webkitTransitionEnd oTransitionEnd');
                    });
                });

                this.input.val(null);
                this.droppedFiles = [];
            } else {
                this.element.addClass(this.settings.class.isSuccess).removeClass(this.settings.class.isUploading);
                this.isUploading = false;
                // Remove the loader after it's content is faded out
                this.loaderContainer.on('transitionend webkitTransitionEnd oTransitionEnd', () => {
                    this.loaderContainer.find('.' + this.settings.class.loader).remove();
                    this.loaderContainer.unbind('transitionend webkitTransitionEnd oTransitionEnd');
                });
                this.showFiles();
            }

            /*
             jQuery.ajax({
             url: gotoAndPlay.ajax_path,
             type: this.settings.ajaxMethod,
             data: this.droppedFiles,
             complete: () => {
             this.element.removeClass(this.settings.isUploadingClass);
             this.isUploading = false
             },
             success: (data) => {
             this.element.addClass(data.success == true ? this.settings.isSuccessClass : this.settings.isErrorClass);

             // Remove the loader after it's content is faded out
             this.loaderContainer.on('transitionend webkitTransitionEnd oTransitionEnd', () => {
             this.loaderContainer.find('.' + this.settings.loaderClass).remove();
             this.loaderContainer.unbind('transitionend webkitTransitionEnd oTransitionEnd');
             });

             if (!data.success) this.errorMsg.text(data.error);
             }
             });
             */
        }

        showFiles(): void {

            this.element.closest('.c-grid').find('.listing__container--new').remove();
            this.element.parent().before(this.getFilesGridHTML());

            this.element.closest('.c-grid').find('.' + this.settings.class.removeButton).on('click', this.onRemoveButtonClick.bind(this));
            this.element.find('.form-upload__remove-file').on('click', () => {
                this.removeFile(0);
            });

        }

        addFiles(files: any[] = []): void {
            let imgContainer = this.element.parent();
            if ($('body').hasClass('is-admin')) {
                imgContainer = this.element;
            }

            imgContainer.parent().find('.js-image-replace').remove();
            let added = 0;
            for (let h = 0; h < files.length; h++) {
                if ($('#image-' + files[h].id).length == 0) {
                    added = added ? added : h;
                    imgContainer.before(files[h].html);
                }
            }

            if (added) {
                imgContainer.parent().children().eq(added - 1).find('.js-move-item[data-dir="forward"]').removeClass('h-hidden');
            }

            if (files.length) {
                this.element.removeClass(this.settings.class.large).addClass(this.settings.class.small);
            } else {
                this.element.removeClass(this.settings.class.small).addClass(this.settings.class.large);
            }
        }

        updateInputs(list: any[] = []): void {
            let inputs = this.element.find('.js-image-key');
            inputs.each((i, e) => {
                let elem = $(e);
                if (typeof list[i] !== 'undefined') {
                    elem.val(list[i]);
                }
            });
        }

        getLoaderHTML(): string {

            return '<div class="' + this.settings.class.loader + '"></div>';
        }

        getFilesListHTML(): string {

            let html = '';

            html += '<ul class="form-upload__list">';
            jQuery.each(this.droppedFiles, (index, file) => {
                html += '<li class="form-upload__list-item" data-index="' + index + '">';
                html += this.getIconHTML('document-new');
                html += file.name;
                html += '<span class="form-upload__list-remove">';
                html += this.settings.text.remove;
                html += '</span>';
                html += '</li>';
            });

            if (this.droppedFiles.length < this.settings.maxFileCount) {
                html += '<li class="form-upload__list-item form-upload__list-item--add">';
                html += '<label for="' + this.element.find('input[type="file"]').attr('id') + '">';
                html += this.getIconHTML('upload');
                html += this.settings.text.add;
                html += '</label>';
                html += '</li>';
            }
            html += '</ul>';

            return html;
        }

        getFilesGridHTML() {
            const prevImages = this.element.parents('.c-grid').find('.listing__container').length;
            let html         = '';

            jQuery.each(this.droppedFiles, (index, file) => {
                html += '<div class="listing__container listing__container--new ' + ((index == 0 && prevImages == 0) && !this.element.hasClass(this.settings.class.small) ? 'c-grid__col--sm-12' : 'c-grid__col--sm-6') + '" data-index="' + index + '">';
                html += '<figure class="listing__figure">';
                html += '<img src="' + URL.createObjectURL(file) + '" class="listing__figure-img">';
                html += '</figure>';
                html += '<div class="listing__remove form-upload__list-remove">';
                html += '<a href="#" class="listing__remove-link">';
                html += this.settings.text.removeImage;
                html += '</a>';
                html += this.getIconHTML('close');
                html += '</div>';
                html += '</div>';
            });

            return html;
        }

        getIconHTML(icon: string = ''): string {
            return icon.length ? '<svg class="icon c-icon form-upload__list-item-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + gotoAndPlay.svgPath + '#' + icon + '"></use></svg>' : '';
        }

        validateFileCount(): boolean {
            return this.settings.maxFileCount > this.droppedFiles.length;
        }

        isAdvancedUpload(): boolean {
            let div = document.createElement('div');
            return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
        }

        ifValueExistsInObjectArray(prop: string = '', value: string = '', array: any = this.droppedFiles): boolean {
            return array.some(function(obj) {
                return obj[prop] === value;
            });
        }

        addError(msg: string = ''): void {
            this.errorContainer.text(msg);
            this.element.addClass(this.settings.class.isError);
        }

        removeError(): void {
            this.errorContainer.text('');
            this.element.removeClass(this.settings.class.isError);
        }
    }

    function onInit(): void {
        if ($('.form-upload--multiple').length) {
            $('.form-upload--multiple').fileUpload();
        }
    }

    // Init on document ready
    jQuery(document).ready(onInit);
    $(document).on('reload', (onInit));
}

jQuery.fn.fileUpload = function(settings: gotoAndPlay.IFileUploadSettings = {}) {
    let fileUploads: gotoAndPlay.FileUpload[] = [];
    this.each(function() {
        fileUploads.push(new gotoAndPlay.FileUpload(this, settings));
    });
    return fileUploads;
};
