// namespace gotoAndPlay {
//
//     export class SideNav {
//
//         element: JQuery;
//
//         constructor(target: HTMLElement) {
//             this.element = jQuery(target);
//         }
//
//         click(): void {
//             if (!this.element.hasClass('is-current')) {
//                 this.element.addClass('is-current');
//             }
//         }
//     }
//
//     function setActive(event: JQueryEventObject): void {
//         //event.preventDefault();
//         jQuery('.c-side-nav__menu-item').removeClass('is-current');
//         let active: SideNav = new SideNav(this);
//         active.click();
//     }
//
//     function Init(): void {
//         jQuery('.c-side-nav__menu-item').on('click', setActive);
//     }
//
//     jQuery(document).ready(Init);
// }
