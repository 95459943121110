namespace gotoAndPlay {

    export class Results {
        static openDrawer(event: JQueryEventObject = null) {
            const element: JQuery = $(event.currentTarget);
            const parentContainer: JQuery = element.parents('.results__drawer');

            parentContainer.toggleClass('is-open');
        }

        static scrollTop(event: JQueryEventObject = null) {
            event.preventDefault();

            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }

    $(function () {
        $(document).on('click', '.results__hidden-button', Results.openDrawer);
        $(document).on('click', '.results__footer-button', Results.scrollTop);
    });
}
