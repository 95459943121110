namespace gotoAndPlay {

    export class Search {

        static loader: Loader;
        static submitXhr: JQueryXHR;
        static formData: any = {};
        static isInnerOpen: boolean = false;
        static isOuterOpen: boolean = false;

        static toggleDrawer(event: JQueryEventObject = null) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }

            $('body').toggleClass('h-search-drawer-open');
        }

        static hideDrawer(event: JQueryEventObject = null) {
            $('body').removeClass('h-search-drawer-open');
        }

        static displayFilters(event: JQueryEventObject = null) {
            const searchClass = 'js-search-display';
            let selected = $(this);
            let selectedVal = selected.val();
            let displayItems = $('.' + searchClass);
            let display: boolean = false;

            if (selectedVal && selectedVal.length) {
                displayItems.each((i, e) => {
                    let elem = $(e);
                    if (Array.isArray(selectedVal)) {
                        for (let v = 0; v < selectedVal.length; v++) {
                            display = elem.hasClass(searchClass + '-' + selectedVal[v]);

                            if (!display) {
                                break;
                            }
                        }
                    } else {
                        display = elem.hasClass(searchClass + '-' + selectedVal);
                    }

                    if (display) {
                        elem.removeClass('js-skip-input');
                        // elem.show();
                        elem.closest('.search__filter-col').removeClass('h-hidden');
                    } else {
                        elem.addClass('js-skip-input');
                        // elem.hide();
                        elem.closest('.search__filter-col').addClass('h-hidden');
                    }

                    // also hide the element group, if all items are hidden
                    let group = elem.closest('.search__col-group');
                    if (group.length) {
                        if (group.find('.js-skip-input').length === group.find('.' + searchClass).length) {
                            group.addClass('h-hidden');
                        } else {
                            group.removeClass('h-hidden');
                        }
                    }
                });
            } else {
                displayItems.removeClass('js-skip-input');
                // displayItems.show();
                displayItems.parents('.search__filter-col').removeClass('h-hidden');

                displayItems.parents('.search__col-group').removeClass('h-hidden');
            }
        }

        static submit(event: JQueryEventObject, formClass = '.js-search-form') {
            event.preventDefault();

            const form: JQuery = $(event.currentTarget).hasClass(formClass.substr(1)) ? $(event.currentTarget) : $(event.currentTarget).parents(formClass);
            if (form.length > 0 && Search.formData[formClass] !== form.serialize()) {

                if (!Search.loader) {
                    Search.loader = new Loader($('.js-search-results'), 'sky');
                }

                form.addClass(LOADING_CLASS);

                let baseUrl: string = form.attr('action');

                const params: string = $.param(this.getParams(form));
                const url: string = baseUrl + (params ? '?' + params : '');

                if (window.history.pushState) {
                    const newUrl: string = url;

                    window.history.replaceState({
                        page: newUrl,
                        reload: true,
                    }, document.title, newUrl);
                }

                window.location.href = url;
            }
        }

        static initAutocomplete() {
            const autocomplete: JQuery = $('.autocomplete').not('.is-disabled');

            if (autocomplete.length) {
                API.get('/listings/locations').then((response) => {
                    if (response) {
                        autocomplete.autocomplete({
                            data: response,
                            limit: 10,
                            minLength: 1,
                            onAutocomplete: function(value) {
                                autocomplete.parents('form').find('.js-autocomplete-value').val(value);

                                if ((!Helper.isMobileDevice || autocomplete.parents('.js-search-single').length) && autocomplete.parents('.js-front-search-form').length === 0) {
                                    autocomplete.parents('form').filter(':visible').submit();
                                }
                            },
                            onAutocompleteRemove: function(setIndex) {
                                if (setIndex !== false) {
                                    let current  = autocomplete.filter(':visible');
                                    let selected = current.parents('form').find('.js-autocomplete-value').val();

                                    if (setIndex == -1) {
                                        // if no items acive or no selection available
                                        if (current.val()) {
                                            // display previously selected val, if something is typed
                                            current.val(selected);
                                        } else {
                                            // clear input, if search left empty
                                            current.parents('form').find('.js-autocomplete-value').val('');

                                            if (!Helper.isMobileDevice && !current.parents('.js-search-single').length) {
                                                current.parents('form').submit();
                                            }
                                        }
                                    } else {
                                        if (selected != current.val()) {
                                            // force select active item
                                            const liElement: JQuery = current.parent().find('.autocomplete-content').children('li').eq(setIndex);

                                            if (liElement.length && !current.parents('.js-search-single').length) {
                                                liElement.trigger('mousedown.autocomplete');
                                            }
                                        }
                                    }
                                }
                            },
                        });
                    }
                });

                $(document).on('mouseover', '.autocomplete-content li', (event: JQueryEventObject) => {
                    $(event.currentTarget).parent().find('.active').removeClass('active');
                });
            }
        }

        static getDateString(date: string = null, hour: string, period: string = '', ignoreTime: boolean = false) {
            let formattedDate: string = '';

            if (!date) {
                return '';
            }

            formattedDate = String(moment(date, 'DD.MM.YYYY').format('YYYYMMDD'));

            if (!hour) {
                formattedDate += '0000';
            } else {
                formattedDate += hour + '00';
            }

            if (period) {
                const values  = period.split('-');
                formattedDate = String(moment(formattedDate, 'YYYYMMDDhhmm').add(values[0], values[1]).format('YYYYMMDDhhmm'));
            }

            if (ignoreTime) {
                formattedDate = String(moment(formattedDate, 'YYYYMMDD').format('YYYYMMDD')) + '0000';
            }

            return formattedDate + '00';
        }

        static submitFrontSearch(event: JQueryEventObject, formClass = '.js-front-search-form') {
            event.preventDefault();

            const form: JQuery = $(formClass);

            if (form.length > 0) {
                let baseUrl: string = form.attr('action');

                window.location.href = baseUrl + '?' + $.param(this.getParams(form));
            }
        }

        static getParams(form: JQuery) {
            // pickup fields
            const pickupDate: string = form.find('#datepicker_start_time').val();
            const pickupHour: string = form.find('#datepicker_pickup_hour').val();
            const pickupTimeIgnore: boolean = pickupHour === 'none';

            // return fields
            const periodValue: string = form.find('#period').val();
            const returnDate: string = form.find('#datepicker_end_time').val();
            const returnHour: string = form.find('#datepicker_return_hour').val();
            const returnTimeIgnore: boolean = returnHour === 'none';

            let skipParams = form.hasClass('js-front-search-form') && !form.parent().hasClass('is-open-details');
            let params: any = form.serializeArray();
            let removeParams = ['_token'];

            // remove fields unused by current filter selection
            form.find('[data-in-url="true"]').each((i, el) => {
                let elem = $(el);
                let container = elem.closest('.js-search-display');
                if (container.length && (container.hasClass('js-skip-input') || skipParams)) {
                    removeParams.push(elem.attr('name'));
                }
            });

            // reverse loop ensures key changes wont affect the param order
            for (let p = (params.length - 1); p >= 0; p--) {
                if (removeParams.includes(params[p].name)) {
                    params.splice(p, 1);
                }
            }

            // set values
            if (pickupDate) {
                params.push({
                    name: 'pickup_date',
                    value: this.getDateString(pickupDate, (pickupHour !== 'none' ? pickupHour : '')),
                });
            }

            if (periodValue !== '0' && periodValue !== 'custom') {
                let value = this.getDateString(pickupDate, (pickupHour !== 'none' ? pickupHour : ''), periodValue, pickupTimeIgnore);

                if (value) {
                    params.push({
                        name: 'return_date',
                        value: value,
                    });
                }
            }

            if (periodValue !== '0' && periodValue === 'custom') {
                let value = this.getDateString(returnDate, (returnHour !== 'none' ? returnHour : ''), '', returnTimeIgnore);

                if (value) {
                    params.push({
                        name: 'return_date',
                        value: value,
                    });
                }
            }

            if (this.isOuterOpen) {
                params.push({
                    name: 'is_outer_open',
                    value: '1',
                });
            }

            if (this.isInnerOpen) {
                params.push({
                    name: 'is_outer_open',
                    value: '1',
                });
            }

            return params;
        }

        static openLocation(event: JQueryEventObject = null) {
            const element: JQuery = $(event.currentTarget);
            const parentContainer: JQuery = element.parents('.search__location-col');

            parentContainer.find('.search__control').removeClass('is-active');

            element.toggleClass('is-active');

            // toggle search by current location
            if (element.hasClass('js-search-near')) {
                parentContainer.removeClass('is-open');

                // trigger browser location request
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((location) => {
                        // do nothing, map isn't visible for frontpage search
                    });
                }

                // set search request type
                $('[name="use_location"]').val('1');
            }

            // toggle search by location input
            if (element.hasClass('js-search-location')) {
                parentContainer.addClass('is-open');

                // set search request type
                $('[name="use_location"]').val('0');
            }
        }

        static toggleDetailed(event: JQueryEventObject = null) {
            const element: JQuery = $(event.currentTarget);
            const searchElement: JQuery = element.parents('.search');

            if (element.hasClass('js-open-details')) {
                searchElement.addClass('is-open-details');
            } else {
                searchElement.removeClass('is-open-details');
            }

        }

        static openSizes(event: JQueryEventObject = null) {
            event.preventDefault();

            const element: JQuery = $(event.currentTarget);

            element.find('.search__filter-open-size-button-text').css({display: 'none'});

            if (element.hasClass('js-open-outer')) {
                if (element.parents('.search__filter').find('.search__outer-col').hasClass('search__hidden-col')) {
                    Search.isOuterOpen = true;

                    element.parents('.search__filter').find('.search__outer-col').removeClass('search__hidden-col');
                    element.find('.search__filter-open-size-button-text-close').css({display: 'inline'});
                    element.find('.search__filter-open-size-button-text-open').css({display: 'none'});
                } else {
                    Search.isOuterOpen = false;

                    element.parents('.search__filter').find('.search__outer-col').addClass('search__hidden-col');
                    element.find('.search__filter-open-size-button-text-close').css({display: 'none'});
                    element.find('.search__filter-open-size-button-text-open').css({display: 'inline'});
                }
            } else if (element.hasClass('js-open-inner')) {
                if (element.parents('.search').find('.search__inner-col').hasClass('search__hidden-col')) {
                    Search.isInnerOpen = true;

                    element.parents('.search').find('.search__inner-col').removeClass('search__hidden-col');
                    element.find('.search__filter-open-size-button-text-close').css({display: 'inline'});
                    element.find('.search__filter-open-size-button-text-open').css({display: 'none'});
                } else {
                    Search.isInnerOpen = false;

                    element.parents('.search').find('.search__inner-col').addClass('search__hidden-col');
                    element.find('.search__filter-open-size-button-text-close').css({display: 'none'});
                    element.find('.search__filter-open-size-button-text-open').css({display: 'inline'});
                }
            }

        }

        static toggleMapArea(event: JQueryEventObject): void {
            event.preventDefault();

            $('body').toggleClass('map-area-is-open');
            let currentUrl = new URL(window.location.href);

            if ($('body').hasClass('map-area-is-open')) {
                $('.js-is-map-area-open').val('true');
                currentUrl.searchParams.set('is_map_area_open', 'true');

                // add map toggle history state for back navigation
                if (window.history.pushState) {
                    window.history.pushState({
                        mapToggle: true,
                    }, '', currentUrl.toString());
                }
            } else {
                $('.js-is-map-area-open').val('false');
                currentUrl.searchParams.set('is_map_area_open', 'false');

                // reset map toggle history state
                if (window.history.state && window.history.state.mapToggle) {
                    window.history.replaceState({
                        mapToggle: false,
                    }, '', currentUrl.toString());
                }
            }
        }

        static handleBackButton(event: PopStateEvent): void {
            // close map area on back button click
            if (event.state && !event.state.mapToggle) {
                $('body').removeClass('map-area-is-open');
                $('.js-is-map-area-open').val('false');
            }
        }

        static toggleFilter(event: JQueryEventObject): void {
            event.preventDefault();

            const filter: JQuery = $(document).find('.map-area .search--filter');
            const target: JQuery = $(event.currentTarget).find('span');

            filter.toggleClass('is-open');
            target.toggleClass('is-visible');
        }
    }

    $(function() {
        $(document).on('click', '.search__control', Search.openLocation);
        $(document).on('click', '.search__filter-open-size-button', Search.openSizes);
        $(document).on('click', '.js-open-details', Search.toggleDetailed);
        $(document).on('click', '.js-close-details', Search.toggleDetailed);
        $(document).on('click', '.js-search-drawer', Search.toggleDrawer);
        $(document).on('click', '.js-search-drawer-close', Search.hideDrawer);
        $(document).on('change', 'select.js-search-display-selector', Search.displayFilters);
        $(document).on('submit', '.js-search-form', Search.submit);

        if ($('.map-area').length) {
            $(document).on('click', '.js-toggle-map-area', Search.toggleMapArea);
            addEventListener('popstate', Search.handleBackButton);
            // $(window).on('popstate', Search.closeMapArea);
        }

        let searchInterval: any;

        Search.formData = {
            '.js-search-form': $('.js-search-form').length ? $('.js-search-form').serialize() : null,
            '.js-mobile-search-form': $('.js-mobile-search-form').length ? $('.js-mobile-search-form').serialize() : null,
        };

        $(document).on('change blur noUiChange', '.js-search-form :input', function(event) {
            let eventParent = $(event.target).parent();

            // skip form submit for autocomplete textfield changes, unless it's left empty
            if (eventParent.is('form') || !eventParent.find('.autocomplete').length) {
                // submit form with debounce time for updates
                if (searchInterval) {
                    clearTimeout(searchInterval);
                }

                if ($(document).width() > 1023 && !$(event.target).parents().hasClass('js-map-area-form')) {
                    searchInterval = setTimeout(function() {
                        Search.submit(event);
                    }, 350);
                }
            }
        });

        // skip location search submit, unless selected by autocomplete
        $(document).on('submit', '.js-search-single', function(event: JQueryEventObject) {
            if (!$(event.target).find('.js-autocomplete-value').val()) {
                event.preventDefault();
                return false;
            }
        });

        $(document).on('focus', '.autocomplete', function(event: JQueryEventObject) {
            let elem = $(this);
            if (Helper.isMobileDevice) {
                if (elem.closest('.modaal-wrapper').length) {
                    $('.modaal-wrapper').animate({
                        scrollTop: 80,
                    }, 500);
                } else {
                    Helper.scrollToTarget(elem);
                }
            }
        });

        $(document).on('submit', '.js-mobile-search-form', function(event) {
            Search.submit(event, '.js-mobile-search-form');

            $('.js-modal-close').trigger('click');
        });

        $(document).on('submit', '.js-front-search-form', function(event) {
            Search.submitFrontSearch(event);
        });

        $(document).ready(function() {
            Search.initAutocomplete();

            $('select.js-search-display-selector').change();
            $(document).on('reload', Search.initAutocomplete);
        });

        $(document).on('change', '.js-hidden-location', function(event) {
            Search.submit(event, '.js-search-form');
        });

        $(document).on('click', '.search__filter-mobile-submit-button', function(event) {
            if ($(event.target).parents().hasClass('js-map-area-form')) {
                Search.submit(event, '.js-map-area-form');
            } else {
                Search.submit(event);
            }
        });

        $(document).on('click', '.js-open-filter', Search.toggleFilter);
    });
}
