namespace gotoAndPlay {

    $(function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
        });

        let $optionType = $('.js-option-type select');
        let $options = $('.js-options');
        let $unit = $('.js-unit');
        let $submitOptions = $('.js-submit-options');
        let $optionItems = $('#reorder_items');

        $optionType.on('change', function () {
            let val = $(this).val();

            if (val == 'dropdown') {
                $options.removeClass('h-hidden');
                $unit.addClass('h-hidden');
            } else if (val == 'input_small') {
                $unit.removeClass('h-hidden');
                $options.addClass('h-hidden');
            } else {
                $unit.addClass('h-hidden');
                $options.addClass('h-hidden');
            }
        });

        $submitOptions.on('click', function () {
            let optionName = $('input[name="option"]');
            let optionValues = $('input[name="options"]');
            let tooltip = $('input[name="tooltip"]');
            let type = $('select[name="field_type"]');
            let unit = $('input[name="unit"]');
            let typeID = $(this).data('id');

            $.ajax({
                url: gotoAndPlay.templatePath + '/admin/settings/options/create',
                method: 'POST',
                dataType: 'json',
                data: {
                    option: optionName.val(),
                    options: optionValues.val(),
                    type_id: typeID,
                    type: type.val(),
                    tooltip: tooltip.val(),
                    unit: unit.val(),
                },
            }).done(function (data) {
                optionName.val('');
                optionValues.val('');
                tooltip.val('');
                unit.val('');
                $optionItems.append(data);
            });
        });

        let $submitFeature = $('.js-submit-feature');
        let $featureItems = $('.js-feature-items');

        $submitFeature.on('click', function () {
            let featureName = $('input[name="feature"]');
            let typeID = $(this).data('id');

            $.ajax({
                url: gotoAndPlay.templatePath + '/admin/settings/features/create',
                method: 'POST',
                dataType: 'json',
                data: {
                    name: featureName.val(),
                    type_id: typeID,
                    public: 1,
                },
            }).done(function (data) {
                featureName.val('');
                $featureItems.append(data);
            });
        });

        let $submitModel = $('.js-submit-model');
        let $modelItems = $('.js-model-items');

        $submitModel.on('click', function () {
            let $modelName = $('input[name="model"]');
            let markID = $(this).data('id');

            $.ajax({
                url: gotoAndPlay.templatePath + '/admin/settings/model/create',
                method: 'POST',
                dataType: 'json',
                data: {
                    model: $modelName.val(),
                    mark_id: markID,
                },
            }).done(function (data) {
                $modelName.val('');
                $modelItems.append(data);
            });
        });
    });
}
