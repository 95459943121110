namespace gotoAndPlay {
    class Station {
        private dockXhr: JQueryXHR;

        public findDocks(venueId: number) {
            if (this.dockXhr) {
                this.dockXhr.abort();
            }
            let dockSelect = $('select[name="dock_id"]');
            dockSelect.empty();
            this.dockXhr = API.get('/station/' + venueId + '/docks');
            let loader   = new Loader($('.js-station-loader'));
            this.dockXhr.then((response) => {
                if (response.success && response.docks) {
                    $.each(response.docks, (i, dock) => {
                        dockSelect.append('<option value="' + dock.dock_index + '">' + (dock.label ? dock.label : dock.dock_index) + '</option>');
                    });
                    $(document).trigger('reload');
                }
                loader.remove();
            }).fail(() => {
                loader.remove();
            });

            return this.dockXhr;
        }
    }

    export let station = new Station();

    $(function() {
        $(document).on('change', '.js-station-new select[name="venue_id"]', function(event) {
            station.findDocks($(this).val());
        });
    });
}
