// import '/import/js/jquery.daterangepicker.min.js';

namespace gotoAndPlay {
    $(function() {
        AvailabilityPicker.setMomentLocale();

        function initDatepickers() {
            let timeSliders = [];
            let userTimeSelected = false;

            if ($('.js-datepicker-double').length > 0) {
                $('.js-datepicker-double').each(function(i: number, e: HTMLElement) {
                    const element: JQuery = $(e);
                    const inline: boolean = !!element.data('inline');
                    const hasDatepicker: boolean = !!element.find('.date-picker-wrapper').length;

                    if (inline && hasDatepicker) {
                        return;
                    }

                    if ($(this).data('dateRangePicker')) {
                        $(this).data('dateRangePicker').destroy();
                        $(this).data('dateRangePicker', null);
                    }
                    if (!$(this).parent().data('html')) {
                        $(this).parent().data('html', $(this).parent().html());
                    } else {
                        $(this).parent().html($(this).parent().data('html'));
                    }
                });
                $('.js-datepicker-double').filter(':visible').each(function(di: number, de: HTMLElement) {
                    const element: JQuery = $(de);
                    const inline: boolean = !!element.data('inline');
                    const hasDatepicker: boolean = !!element.find('.date-picker-wrapper').length;

                    // skip re-init of inline datepickers
                    if (inline && hasDatepicker) {
                        return;
                    }

                    // has start date set from request
                    let hasStart = !!element.data('start-preset');
                    // default/min start date of listing
                    let initStart = element.data('start-date') ? moment(element.data('start-date'), 'YYYY.MM.DD hh:mm') : moment('undefined');
                    // initial selected length - defaults to 1 day
                    let startLength = 24;
                    // min length of booking
                    let minLength = element.data('min-length') ? parseInt(element.data('min-length')) : 0;

                    let dateRangePicker = element.dateRangePicker({
                        showButtonPanel: false,
                        language: gotoAndPlay.lang,
                        format: 'DD.MM.YY HH',
                        displayFormat: 'DD.MM.YY',
                        time: {
                            enabled: true,
                        },
                        singleMonth: false,
                        autoClose: false,
                        hoveringTooltip: false,
                        showTopbar: true,
                        startOfWeek: 'monday',
                        startDate: new Date(),
                        startLength: minLength,
                        numberOfMonths: 2,
                        customArrowPrevSymbol: Icon.render('caret-left', 'datepicker__double-left-arrow'),
                        customArrowNextSymbol: Icon.render('caret-right', 'datepicker__double-right-arrow'),
                        separator: ' - ',
                        inline: inline,
                        alwaysOpen: inline,
                        container: inline ? '.js-availability-datepicker-container' : 'body',
                        customOpenAnimation: function(cb) {
                            $(this).show();
                        },
                        customCloseAnimation: function(cb) {
                            $(this).hide();
                        },
                        extraClass: 'datepicker-double__dom',
                        setValue: function(s, s1, s2) {
                            let pickupInput = $(this).find('input[data-pickup]');
                            let returnInput = $(this).find('input[data-return]');
                            let rangeInput = $(this).find('[data-range]');
                            let rangeDisplay = $('.js-range-display');
                            let pickupMoment = (moment(s1, 'DD.MM.YYYY HH'));
                            let returnMoment = (moment(s2, 'DD.MM.YYYY HH'));
                            if (pickupInput.length) {
                                pickupInput.val(pickupMoment.format('MMM D, HH:mm'));
                            }
                            if (returnInput.length) {
                                returnInput.val(returnMoment.format('MMM D, HH:mm'));
                            }
                            if (rangeInput.length) {
                                let pickupDate = pickupMoment.format('MMM D');
                                let pickupTime = pickupMoment.format('HH:mm');
                                let returnDate = returnMoment.format('MMM D');
                                let returnTime = returnMoment.format('HH:mm');
                                rangeInput.html('<strong>' + pickupDate + '</strong>, ' + pickupTime + ' <span class="datepicker__double-dash">-</span> ' + '<strong>' + returnDate + '</strong>, ' + returnTime);

                                if (rangeDisplay.length) {
                                    rangeDisplay.html('<strong>' + pickupDate + '</strong>, ' + pickupTime + ' <span class="datepicker__double-dash">-</span> ' + '<strong>' + returnDate + '</strong>, ' + returnTime);
                                }
                            }
                            $(this).find('input[name="pickup_date"]').val(pickupMoment.format('YYYYMMDDHHmm00'));
                            $(this).find('input[name="return_date"]').val(returnMoment.format('YYYYMMDDHHmm00'));
                            // replace parent html cahce, as set input values have changed
                            $(this).parent().data('html', $(this).parent().html());
                        },
                    }).bind('datepicker-open', function(event, obj) {
                        if (!inline) {
                            $('body').addClass('datepicker-open');
                        }

                        // ToDo: dedicated initial load handler
                        let pickupInput = $(this).find('input[data-pickup]');
                        let returnInput = $(this).find('input[data-return]');
                        let labels = $(this).data('form-labels');
                        if (pickupInput.length && returnInput.length) {
                            if (returnInput.is(':focus')) {
                                $(this).addClass('return-first');
                                pickupInput.removeClass('pickup-first');
                            }
                            if (pickupInput.is(':focus')) {
                                $(this).addClass('pickup-first');
                                returnInput.removeClass('return-first');
                            }
                        }

                        if (($('.time1:visible').children().length < 3) && ($('.time2:visible').children().length < 3)) {
                            let time1select = '<div class="c-form-select c-form-select--time "><label class="c-form-select__label" for="select">' + labels.pickup_at + '</label><select name="select" id="selectPickup" class="c-form-select__input is-native">';
                            let time2select = '<div class="c-form-select c-form-select--time "><label class="c-form-select__label" for="select">' + labels.return_at + '</label><select name="select" id="selectDropOff" class="c-form-select__input is-native">';
                            for (let i = 0; i < 24; i++) {
                                let time;
                                if (i.toString().length == 1) {
                                    time = '0' + i;
                                } else {
                                    time = i;
                                }
                                time1select += '<option value="' + i + '.00">' + time + ':00</option>';
                                time2select += '<option value="' + i + '.00">' + time + ':00</option>';
                            }
                            time1select += '</select></div>';
                            time2select += '</select></div>';

                            $('.time1:visible').append(time1select);
                            $('.time2:visible').append(time2select);

                            $('.time').trigger('loadselect.select');
                            $('.time select').on('change.userSelect', (selectEvent: JQueryEventObject) => {
                                if (!selectEvent.isTrigger) {
                                    userTimeSelected = true;

                                    $('.time select').off('change.userSelect');
                                }
                            });
                            // $('.time1:visible').find('.c-form-select select').formSelect();
                            // $('.time2:visible').find('.c-form-select select').formSelect();
                        }

                        let slider = $('.js-time-range');
                        let sliderOptions: any = {
                            connect: [true, false],
                            range: {
                                min: 0,
                                max: 24,
                            },
                            step: 1,
                        };
                        slider.each(function() {
                            if (!this.noUiSlider) {
                                const isStartTime = !!$(this).closest('.time1').length;
                                sliderOptions.start = $(this).parent().find('input[type="range"]').val();
                                if (!hasStart && initStart.isValid()) {
                                    if (isStartTime) {
                                        sliderOptions.start = initStart.format('HH');
                                    } else {
                                        if (startLength && startLength < 24) {
                                            sliderOptions.start = moment(initStart).add((startLength + ''), 'h').format('HH');
                                        } else {
                                            sliderOptions.start = initStart.format('HH');
                                        }
                                    }
                                }
                                noUiSlider.create(this, sliderOptions);
                                timeSliders.push(this.noUiSlider);
                                this.noUiSlider.on('update', (value) => {
                                    // update return value, if updated slider is pickup(1st)
                                    if (isStartTime) {
                                        let current = $(this).parent().find('input[type="range"]').val();
                                        let startHours = parseInt(value[0]);
                                        let endInput = $(this).closest('.time').find('.time2');
                                        let endSelect = endInput.find('select');
                                        let endHours = endInput.find('input[type="range"]').val();
                                        let diff = (startHours - current);
                                        let selectVal = `${ parseInt(endHours) + diff }.00`;

                                        // set new selected time and rebuild input
                                        if (endSelect.val() !== selectVal) {
                                            const option = endSelect.find(`option[value="${ selectVal }"]:not([disabled])`);

                                            if (option.length) {
                                                // set selected time
                                                endSelect.val(selectVal);
                                                endSelect.trigger('change');
                                                endInput.trigger('loadselect.select');
                                            } else {
                                                // set last available time
                                                const lastOpt = endSelect.find('option:not([disabled])').last();

                                                if (!lastOpt.is(':selected')) {
                                                    endSelect.val(lastOpt.attr('value'));
                                                    endSelect.trigger('change');
                                                    endInput.trigger('loadselect.select');
                                                }
                                            }
                                        }

                                        // $(this).closest('.time').find('.time2 input[type="range"]').val(parseInt(endHours) + diff).trigger('change');
                                        /*
                                        if (typeof timeSliders[1] !== 'undefined') {
                                            timeSliders[1].set(parseInt(endHours) + diff);
                                        }
                                         */
                                        // changing select also updates related slider

                                    }

                                    $(this).parent().find('input[type="range"]').val(value[0]).trigger('change');
                                });

                                let select = $($(this).parents()[1]).find('select');
                                let target = this.noUiSlider;
                                select.val(target.get()); // set inital value of selects
                                $(select).on('change', function() {
                                    target.set(select.val()); // update internal value on select change
                                });
                            }
                        });

                        $('.day.valid').on('click', function(e) {
                            if ($('.date-picker-wrapper').find('.last-date-selected').length >= 1) {
                                $('.date-picker-wrapper').find('.day.valid').removeClass('listening listening-prev listening-next');
                            } else {
                                $(this).addClass('listening');
                                $(this).parent().prev().find('.day.valid').addClass('listening-prev');
                                $(this).parent().next().find('.day.valid').addClass('listening-next');
                            }
                        });

                        if ($('.date-picker-wrapper').find('.js-close-datepicker').length < 1) {
                            if (window.innerWidth <= 768 || inline) {
                                $('.time1:visible').children().slice(0, 2).hide();
                                $('.time2:visible').children().slice(0, 2).hide();

                                if (!inline) {
                                    let closeHtml = '<a href="#" class="date-picker-close js-close-datepicker"></a>';
                                    $('.drp_top-bar').append(closeHtml);
                                    $('.js-close-datepicker').on('click', function() {
                                        dateRangePicker.data('dateRangePicker').close();
                                    });

                                    let btnHtml = '<button type="button" class="c-button c-button--block js-apply-datepicker datepicker__apply h-hidden-sm h-hidden-lg h-hidden-md">' + labels.select_date + '</button>';
                                    $('.time').append(btnHtml);

                                    $('.js-apply-datepicker').on('click', function() {
                                        const selectedDate = $('.date-picker-wrapper .day.first-date-selected');
                                        if (selectedDate.hasClass('last-date-selected')) {
                                            let date1 = parseInt($('.date-picker-wrapper .time1 select').val());
                                            let date2 = parseInt($('.date-picker-wrapper .time2 select').val());
                                            let isToday = selectedDate.hasClass('real-today');

                                            const diffHours = date2 - date1;

                                            // ToDo: too many duplicates
                                            if (diffHours < minLength) {
                                                const startHours = date1;
                                                const endHours = date2;
                                                let applyHours = minLength - (diffHours);

                                                // add hours to end, without passing a day
                                                const moveEnd = Math.min(applyHours, 23 - endHours);
                                                date2 += moveEnd;
                                                applyHours = applyHours - moveEnd;

                                                // add rest of hours to start
                                                if (applyHours > 0) {
                                                    let moveStart;
                                                    if (isToday) {
                                                        // when on first pickable day, avoid going under start time
                                                        moveStart = Math.min(startHours - initStart.get('hours'), applyHours);
                                                    } else {
                                                        // on full day, allow going up to the start of day
                                                        moveStart = Math.min(startHours, applyHours);
                                                    }

                                                    date1 -= moveStart;
                                                }

                                                // update values for mobile datepicker selects
                                                $('.time1 select').val(date1.toFixed(2));
                                                $('.time2 select').val(date2.toFixed(2));
                                            }
                                        }

                                        $('.date-picker-wrapper .time select').each((i, e): void => {
                                            const select = $(e);
                                            timeSliders[i].set(select.val());
                                        });

                                        dateRangePicker.data('dateRangePicker').close();
                                    });
                                }
                            } else {
                                $('.time1:visible').children().slice(2, 4).hide();
                                $('.time2:visible').children().slice(2, 4).hide();

                                // append custom close button for desktop
                                let closeBtn = '<button type="button" class="c-button datepicker-double__close js-close-datepicker">' + labels.close + '</button>';
                                $('.date-picker-wrapper .footer').append(closeBtn);

                                $('.js-close-datepicker').on('click', function() {
                                    dateRangePicker.data('dateRangePicker').close();
                                });
                            }
                        }

                        let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
                        if (iOS) {
                            $('.day.valid').on('touchstart', function(e) {
                                e.preventDefault();
                                $(e.target).trigger('click');
                            });
                        }

                        $('.time').addClass('time--ready');
                    }).bind('datepicker-first-date-selected', function(event, obj) {
                        let pickupInput = $(this).find('input[data-pickup]');
                        let returnInput = $(this).find('input[data-return]');
                        let rangeInput = $(this).find('input[data-range]');
                        if (returnInput.length && pickupInput.length) {
                            if ($(this).hasClass('return-first')) {
                                returnInput.removeClass('c-form-textfield__input--focus');
                                pickupInput.addClass('c-form-textfield__input--focus');
                            } else if ($(this).hasClass('pickup-first')) {
                                returnInput.addClass('c-form-textfield__input--focus');
                                pickupInput.removeClass('c-form-textfield__input--focus');
                            }
                        }
                        if (rangeInput.length) {
                            rangeInput.addClass('c-form-textfield__input--focus');
                        }

                        let applyBtn = $('.js-apply-datepicker');
                        applyBtn.prop('disabled', true);
                    }).bind('datepicker-last-date-selected', function(event, obj) {
                        const date1 = moment(obj.date1);
                        const date2 = moment(obj.date2);

                        // validate hours to min allowed, if one date is selected
                        if (date1.format('YYYYMMDD') === date2.format('YYYYMMDD')) {
                            const diffHours = date2.diff(date1) / 1000 / 60 / 60;
                            const startHours = date1.get('hours');
                            const endHours = date2.get('hours');
                            let applyHours = minLength - (diffHours);

                            if (diffHours < minLength) {
                                // add hours to end, without passing a day
                                const moveEnd = Math.min(applyHours, 23 - endHours);
                                date2.add(moveEnd, 'hours');
                                applyHours = applyHours - moveEnd;

                                // add rest of hours to start
                                if (applyHours > 0) {
                                    let moveStart;
                                    if (date1.format('YYYMMDD') === initStart.format('YYYMMDD')) {
                                        // when on first pickable day, avoid going under start time
                                        moveStart = Math.min(startHours - initStart.get('hours'), applyHours);
                                    } else {
                                        // on full day, allow going up to the start of day
                                        moveStart = Math.min(startHours, applyHours);
                                    }

                                    date1.subtract(moveStart, 'hours');
                                }

                                timeSliders[0].set(date1.get('hours'));
                                timeSliders[1].set(date2.get('hours'));

                                if ($('.time select').length) {
                                    // update values for mobile datepicker selects
                                    $('.time1 select').val(date1.format('H.00'));
                                    $('.time2 select').val(date2.format('H.00'));
                                }
                            }
                        } else if (!userTimeSelected) {
                            // if moving to multi-day selection before user has time selected, set fixed 24h range
                            const startHours = date1.get('hours');
                            const endHours = date2.get('hours');

                            // a.k.a set day2 hours equal to day1
                            date2.add(startHours - endHours, 'hours');
                            timeSliders[1].set(date2.get('hours'));

                            if ($('.time select').length) {
                                // update values for mobile datepicker selects
                                $('.time2 select').val(date2.format('H.00'));
                            }
                        }

                        // dateRangePicker.data('dateRangePicker').setDateRange(startDate.toDate(), endDate.toDate());
                    }).bind('datepicker-change', function(event, obj) {
                        let applyBtn = $('.js-apply-datepicker');
                        applyBtn.prop('disabled', false);
                    }).bind('datepicker-close', function(event, obj) {
                        $('body').removeClass('datepicker-open');

                        let pickupInput = $(this).find('input[data-pickup]');
                        let returnInput = $(this).find('input[data-return]');
                        let rangeInput = $(this).find('input[data-range]');
                        $(this).removeClass('pickup-first return-first');
                        if (pickupInput.length) {
                            pickupInput.removeClass('c-form-textfield__input--focus');
                        }
                        if (returnInput.length) {
                            returnInput.removeClass('c-form-textfield__input--focus');
                        }
                        if (rangeInput.length) {
                            rangeInput.removeClass('c-form-textfield__input--focus');
                        }
                        if ($('.js-search-form').length) {
                            $('.js-search-form').trigger('submit');
                        }
                    });

                    let startDate = dateRangePicker.find('[name="pickup_date"]').val();
                    let endDate = dateRangePicker.find('[name="return_date"]').val();
                    if (!startDate) {
                        if (!hasStart && initStart.isValid()) {
                            startDate = initStart;
                        } else {
                            // startDate = moment().add(1, 'hour');
                        }
                    } else {
                        startDate = moment(startDate, 'YYYYMMDDHHmmss');
                    }
                    if (!endDate) {
                        if (startDate) {
                            endDate = moment(startDate).add(1, 'day');
                        }
                    } else {
                        endDate = moment(endDate, 'YYYYMMDDHHmmss');
                    }
                    if (startDate && endDate) {
                        dateRangePicker.data('dateRangePicker').setDateRange(startDate.toDate(), endDate.toDate());
                    }

                    // render initial state
                    if (inline) {
                        dateRangePicker.bind('availability.loaded', function() {
                            dateRangePicker.data('dateRangePicker').open();
                        });
                    }
                });
                $('.js-datepicker-double input').focus(function() {
                    $('.js-datepicker-double').data('dateRangePicker').open();
                });
            }

            if ($('.js-datepicker-single').length > 0) {
                $(function() {
                    $('.js-datepicker-single').each((i, el) => {
                        if ($(el).data('dateRangePicker')) {
                            $(el).data('dateRangePicker').destroy();
                            $(el).data('dateRangePicker', null);
                        }

                        let dateInput = $(el).find('input');
                        let selected = dateInput.length && dateInput.val() ? dateInput.val() : false;
                        let datePicker = $(el).dateRangePicker({
                            showButtonPanel: false,
                            language: gotoAndPlay.lang,
                            format: 'DD.MM.YYYY',
                            singleDate: true,
                            singleMonth: true,
                            hoveringTooltip: false,
                            showTopbar: false,
                            startOfWeek: 'monday',
                            customArrowPrevSymbol: Icon.render('caret-left', 'datepicker__double-left-arrow'),
                            customArrowNextSymbol: Icon.render('caret-right', 'datepicker__double-right-arrow'),
                            customOpenAnimation: function(cb) {
                                $(this).show();
                            },
                            customCloseAnimation: function(cb) {
                                $(this).hide();
                            },
                            inline: false,
                            minDate: (dateInput.data('allow-past') ? false : moment().format('DD.MM.YYYY')),
                            startDate: (dateInput.data('allow-past') ? false : moment().format('DD.MM.YYYY')),
                            alwaysOpen: false,
                            extraClass: 'datepicker-single__dom',
                            setValue: function(s) {
                                $(this).find('input').val(s).trigger('change');
                            },
                        });

                        if (selected) {
                            datePicker.data('dateRangePicker').setStart(dateInput.val());
                        }
                    });
                });
            }
        }

        initDatepickers();

        $(document).on('reload', function() {
            initDatepickers();
        });

        $('.js-availability-datepicker').each(function() {
            let availabilityPicker = new AvailabilityPicker($(this));
        });
    });
}
