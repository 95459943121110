// import('import/js/jquery.mark.js');

namespace gotoAndPlay {

    $(function () {
        $(document).on('input', '.js-faq-search', function () {
            let term = $(this).val();
            let $context = $('.js-faq-search-item');
            $context.show().unmark();
            if (term) {
                $context.mark(term, {
                    done: function () {
                        $context.not(':has(mark)').hide();
                        if ($context.is(':visible')) {
                            $('.js-faq-empty').hide();
                        } else {
                            $('.js-faq-empty').show();
                        }
                    },
                });
            }
        });
    });
}
