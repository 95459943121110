// import('import/js/nouislider.js');
// import('import/js/wNumb.js');

namespace gotoAndPlay {

    function initSliders() {
        /**
         * Handle Range Slider's stuff
         */
        if ($('.js-nouislider').length) {
            let sliders = $('.js-nouislider');

            for (let i = 0; i < sliders.length; i++) {
                noUiSliderSetup(sliders, i);
            }
        }

        function isInRange(value: number, min: number, max: number, inclusive: boolean = false) {
            if (value && min && max) {
                return inclusive ? (value >= min && value <= max) : (value > min && value < max);
            }

            return false;
        }

        function changeBarsColors(bars: JQuery, fromValue: number, toValue: number) {
            bars.each((index: number, elem: Element) => {
                const element: JQuery = $(elem);
                const min: number = element.data('min');
                const max: number = element.data('max');

                if (isInRange(((min + max) / 2), fromValue, toValue, true)) {
                    element.addClass('is-active');
                } else {
                    element.removeClass('is-active');
                }
            });
        }

        function noUiSliderSetup(sliders: any, i: number) {
            let slider: any = sliders[i];
            let $slider = $(slider);
            let element = $slider.parents('.c-range-slider');
            let $inputNumberFrom = $slider.parent().find('.js-range-min');
            let $inputNumberTo = $slider.parent().find('.js-range-max');
            let fromValue = $slider.data('from') ? $slider.data('from') : 0;
            let toValue = $slider.data('to') ? $slider.data('to') : 0;
            let minValue = $slider.data('min') ? $slider.data('min') : 0;
            let maxValue = $slider.data('max') ? $slider.data('max') : 100;
            let step = $slider.data('step') ? $slider.data('step') : 1;
            let nullable = typeof $slider.data('nullable') !== 'undefined' && $slider.data('nullable');
            let isFancy = element.hasClass('c-range-slider--fancy');
            let titleFrom: JQuery = null;
            let titleTo: JQuery = null;
            let bars: JQuery = null;

            if (isFancy) {
                titleFrom = element.find('.c-range-slider__value-from');
                titleTo = element.find('.c-range-slider__value-to');
                bars = element.find('.c-range-slider__item');

                if (bars.length > 0) {
                    changeBarsColors(bars, fromValue, toValue);
                }
            }

            if (!slider.noUiSlider && $slider.find('.noUi-base').length) {
                $slider.find('.noUi-base').remove();
                // add/remove single step value to allow range overflow used as empty
                fromValue = parseFloat($inputNumberFrom.val() && $inputNumberFrom.val() != '-' ? $inputNumberFrom.val() : fromValue) - (nullable ? step : 0);
                toValue = parseFloat($inputNumberTo.val() && $inputNumberTo.val() != '-' ? $inputNumberTo.val() : toValue) + (nullable ? step : 0);
            }

            let sliderOptions: any = {
                start: [fromValue, toValue],
                connect: true,
                range: {
                    min: minValue - (nullable ? step : 0),
                    max: maxValue + (nullable ? step : 0),
                },
                step: step,
                format: wNumb({
                    decimals: step < 1 ? 2 : 0,
                    thousand: '',
                    postfix: $slider.data('suffix') ? $slider.data('suffix') : '',
                }),
            };

            if (!slider.noUiSlider) {
                noUiSlider.create(slider, sliderOptions);
                slider.noUiSlider.on('update', function (values, handle) {
                    let value = values[handle];

                    if (handle) {
                        $inputNumberTo.val(!value || value > maxValue ? '-' : value);
                        $inputNumberTo.trigger('noUiChange');
                        if (isFancy) {
                            titleTo.text(!value || value > maxValue ? '-' : value);
                            changeBarsColors(bars, parseInt(titleFrom.text()), parseInt(titleTo.text()));
                        }
                    } else {
                        $inputNumberFrom.val(!value || value < minValue ? '-' : value);
                        $inputNumberFrom.trigger('noUiChange');

                        if (isFancy) {
                            titleFrom.text(!value || value < minValue ? '-' : value);
                            changeBarsColors(bars, parseInt(titleFrom.text()), parseInt(titleTo.text()));
                        }
                    }
                });

                $inputNumberFrom.on('change', function () {
                    if (nullable && this.value == '-') {
                        this.value = minValue - step;
                    }

                    slider.noUiSlider.set([this.value, null]);
                });
                $inputNumberTo.on('change', function () {
                    if (nullable && this.value == '-') {
                        this.value = maxValue + step;
                    }

                    slider.noUiSlider.set([null, this.value]);
                });
            }
        }
    }

    $(document).ready(initSliders);
    $(document).on('reload', initSliders);
}
