namespace gotoAndPlay {

    class Booking {

        private calculationXhr: JQueryXHR;

        public calculate(data: {
            listingId: number;
            pickup_date: string,
            return_date: string,
        } = null) {
            if (!data) {
                data = {
                    listingId: $('.js-availability-datepicker').data('listing-id'),
                    pickup_date: $('.js-pickup-datepicker').val(),
                    return_date: $('.js-return-datepicker').val(),
                };
            }
            if (this.calculationXhr) {
                this.calculationXhr.abort();
            }
            this.calculationXhr = API.post('/listings/' + data.listingId + '/calculate', data);
            let loader          = new Loader($('.js-calculation-loader'));
            this.calculationXhr.then((response) => {
                if (response.is_available) {
                    $('.js-calculation-loader').find('[type="submit"]').removeAttr('disabled');
                    // base price row
                    $('.js-fee-price-label').text(response.calculation.price);
                    $('.js-fee-base-label').text(response.calculation.basePrice);
                    $('.js-fee-base').text(response.calculation.full.formatted);
                    // service row
                    $('.js-fee-service').text(response.calculation.service_fee.formatted);
                    // discount row
                    if (response.calculation.discount.numeric > 0) {
                        // discount type
                        $('.js-fee-discount-label').hide().filter('.js-fee-discount-label-' + response.calculation.discountType).show();
                        $('.js-fee-discount').show().find('[data-fee]').html(response.calculation.discount.formatted);
                    } else {
                        $('.js-fee-discount').hide();
                    }
                    // price row
                    $('.js-fee-price').text(response.calculation.total.formatted);
                    // total row
                    $('.js-fee-total').text(response.calculation.fee.formatted);
                    // remove error
                    $('.js-available-error').html('');
                } else {
                    // TODO show notification maybe? oh yes
                    $('.js-fee-base-label').text(response.basePrice);
                    $('.js-fee-base').text('-');
                    $('.js-fee-service').text('-');
                    $('.js-fee-price').text('-');
                    $('.js-fee-total').text('-');
                    $('.js-fee-discount').hide();
                    $('.js-calculation-loader').find('[type="submit"]').attr('disabled', 'disabed');

                    if (response.disabled_error) {
                        $('.js-available-error').html(response.disabled_error);
                    }
                }

                if (response.contractDisplay) {
                    $('.js-contract-display').attr('href', response.contractDisplay);
                }

                loader.remove();
            }).fail(() => {
                loader.remove();
            });

            return this.calculationXhr;
        }
    }

    export let booking = new Booking();

    $(function() {
        $(document).on('change', '.js-insurance-type', function() {
            $('[data-insurance-type]').addClass('is-hidden');
            let methods = $('[data-insurance-type="' + $(this).val() + '"');
            if (methods.length > 0) {
                methods.removeClass('is-hidden');
                $('.js-insurance-plans').removeClass('h-hidden');
            } else {
                $('.js-insurance-plans').addClass('h-hidden');
            }
        });
    });
}
