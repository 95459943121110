namespace gotoAndPlay {

    export class API {

        public static post(request: string, data: any = {}): JQueryXHR {
            const xhr = $.ajax({
                type: 'POST',
                url: apiBasePath + request,
                data: data,
                dataType: 'json',
            });

            return xhr;
        }

        public static postFiles(request: string, data: File[] = []): JQueryXHR {
            let formData = new FormData();
            for (let f = 0; f < data.length; f++) {
                formData.append('file[]', data[f], data[f].name);
            }

            const xhr = $.ajax({
                type: 'POST',
                url: apiBasePath + request,
                data: formData,
                dataType: 'json',
                contentType: false,
                processData: false,
            });

            return xhr;
        }

        public static get (request): JQueryXHR {
            const xhr = $.ajax({
                type: 'GET',
                url: apiBasePath + request,
                dataType: 'json',
            });

            return xhr;
        }

        public static delete(request): JQueryXHR {
            const xhr = $.ajax({
                type: 'DELETE',
                url: apiBasePath + request,
                dataType: 'json',
            });

            return xhr;
        }
    }
}
