// import '/import/js/tooltipster.bundle.min.js';
// import '/import/js/tooltipster-follower.min.js';

namespace gotoAndPlay {
    $(document).ready(function() {
        if ($('.c-tooltip').length > 0) {
            $('.c-tooltip').tooltipster({
                theme: 'tooltipster-custom',
                maxWidth: 300,
                functionPosition: function(instance, helper, position) {
                    position.coord.top += 10;
                    return position;
                },
            });
        }

        if ($('.js-tooltip-follow').length > 0) {
            $('.js-tooltip-follow').tooltipster({
                theme: 'tooltipster-custom',
                plugins: ['follower'],
                anchor: 'bottom-center',
                maxWidth: 300,
                offset: [0, 10],
            });
        }
    });
}
